<template>
  <div>
    <div class="form-upload-file">
      <div class="header-form w-full">
        <div class="form-name mb-2">{{ $t('ocr_service.label_ocr_reading') }}</div>
        <div class="count-number">
          <div class="label-name" v-html="getLabelNameInfoPlanOcr.text_used_ocr"></div>
          <div class="divider-line"></div>
          <div class="label-name">{{ getLabelNameInfoPlanOcr.text_max_ocr }}</div>
        </div>
      </div>

      <div class="w-full">
        <div>
          <div class="title-btn title-btn-select-scope">
            <span>{{ getPulldownName }}</span>
            <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
          </div>
          <div class="listMenu-btn-text">
            <v-select
              solo
              flat
              :items="listTemplate"
              item-text="template_name"
              item-value="id"
              v-model="formData.template_id"
              :disabled="isShowPreviewFile || disableFormOcrMaxRead || isReadOnly"
              :label="$t('ocr_service.placeholder_select_template')"
              class="select-type select-default list-menu--select select-item mt-2"
              :menu-props="{ contentClass: 'select-menu export-select pulldown-year' }"
            />
          </div>
        </div>
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div class="w-full">
        <div class="title-btn">
          <span>{{ $t('ocr_service.label_upload_read_file') }}</span>
          <div v-show="!isShowPreviewFile" class="title-require">{{ $t('list_menu.label_essential') }}</div>
        </div>
        <div class="csv-file">
          <template v-if="!isShowPreviewFile">
            <div
              class="csv-input"
              v-on:dragenter="dragEnter"
              v-on:dragover="dragOver"
              v-on:dragleave="dragLeave"
              v-on:drop="dropEvent"
            >
              <div class="import-csv">
                <img :src="getIcon('upload')" alt="" class="upload-icon" />
                <div class="csv-input-wrapper">
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    id="csv"
                    ref="csvInput"
                    class="d-none"
                    @change="handleAddFile"
                  />
                  <div class="csv-input-text">{{ $t('list_menu.description_drag_and_drop_the_file_here') }}</div>
                </div>
              </div>
              <common-button
                class="button-import"
                :label="$t('list_menu.button_select_files')"
                type="colored"
                :disabled="isDisable || disableFormOcrMaxRead || isReadOnly"
                @action="$refs.csvInput.click()"
                v-ripple="false"
              />
            </div>

            <div v-if="csvFilename" class="csv-filename">
              <span class="csv-filename-detail">{{ csvFilename }}</span>
              <div class="remove-icon">
                <img
                  class="remove-icon-detail"
                  :src="getIcon(isActiveCloseIcon ? 'remove-active' : 'remove')"
                  alt=""
                  @click="handleDeleteFile"
                  @mouseenter="isActiveCloseIcon = true"
                  @mouseleave="isActiveCloseIcon = false"
                />
              </div>
            </div>
          </template>

          <div v-else class="mt-2">
            <div v-if="imagePreview" class="csv-input">
              <img :src="imagePreview" />
            </div>

            <div v-else class="show-pdf" id="pdf-file"></div>
          </div>
        </div>
        <div class="csv-description" v-show="!isShowPreviewFile">
          {{ $t('ocr_service.description_maximum_upload_size', { number: 5 }) }}
        </div>

        <div v-show="!isShowPreviewFile" class="mt-2 button-read-file">
          <common-button
            class="button-import"
            :label="$t('ocr_service.button_read_file')"
            type="colored"
            v-ripple="false"
            :loading="isDisable"
            @action="showWarningReadFile"
            :disabled="validateUploadImage"
          />
        </div>
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
    </div>
    <notification-popup
      :dialog="dialogNotification"
      :message="$t('idea_db_source.popup_create_success')"
      @submit="navigateAfterSubmitSuccess"
    />

    <ErrorPopup :dialog="dialogLimit" isErrorImg :message="messageLimit" @submit="dialogLimit = false" />
    <question-popup
      :dialog="dialogQuestionUseOcr"
      :message="$t('ocr_service.message_confirm_use_ocr')"
      @close="dialogQuestionUseOcr = false"
      classes="change-method"
      isSaveButtonDelete
      @submit="readFile()"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import InputField from '@/components/products/input/InputField.vue';
import DataReadFile from '@/views/emissions/ReadOcrService/components/dataReadFile.vue';
import PDFObject from 'pdfobject';
import { getFileReadOcr } from '@/api/ocr-service';
import QuestionPopup from '@/components/dialogs/question-popup';

export default {
  components: {
    PageTitle,
    CommonButton,
    NotificationPopup,
    ErrorPopup,
    InputField,
    DataReadFile,
    QuestionPopup,
  },

  props: {
    typeForm: {
      type: String,
      require: true,
    },
    formData: {
      type: Object,
      default() {
        return { data: '', template_id: '' };
      },
    },
    statusDialog: {
      type: Boolean,
      default: false,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    listTemplate: {
      type: Array,
      default: [],
    },
    infoPlanOcr: {
      default: {
        count_used_ocr: null,
        setting_ocr_unit_price: null,
      },
      type: Object,
    },
    dataRow: {
      type: Object,
      default: () => {},
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    energyType: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialogNotification: false,
      isLoading: false,

      messageLimit: '',
      csvFilename: '',
      csvFileData: '',
      dialogCsv: false,
      limitSize: false,
      isFixedButton: true,
      errorInputdate: '',
      dialogLimit: false,
      isUpdateFile: false,
      typeFileUpload: ['image/png', 'image/jpeg', 'application/pdf'],
      isActiveCloseIcon: false,
      imagePreview: null,
      pdfPreview: null,
      dataSubmit: {},
      dialogQuestionUseOcr: false,
    };
  },
  watch: {
    typeForm(value) {
      if (value === 'view') {
        if (this.pdfPreview) {
          this.$nextTick(() => {
            document.getElementById('pdf-file').src = this.pdfPreview;
            PDFObject.embed(this.pdfPreview, '#pdf-file', { id: 'pdfFileOcr' });
          });
        }
      } else {
        this.pdfPreview = null;
        this.imagePreview = null;
        this.csvFilename = null;
        this.formData.data = null;
        this.formData.template_id = null;
        const elements = document.getElementById('pdfFileOcr');
        if (elements) elements.remove();
      }
    },

    statusDialog(value) {
      if (!value) {
        this.handleDeleteFile();
        this.formData.template_id = '';
      }
    },
  },

  computed: {
    ...mapState('userData', ['currentUser', 'language', 'existDbSource']),

    isShowPreviewFile() {
      if (this.typeForm === 'view') {
        if (this.dataRow.file_ocr) {
          this.getFile();
        } else if (this.pdfPreview) {
          this.$nextTick(() => {
            document.getElementById('pdf-file').src = this.pdfPreview;
            PDFObject.embed(this.pdfPreview, '#pdf-file', { id: 'pdfFileOcr' });
          });
        }
      }
      return this.typeForm === 'view';
    },
    getLabelNameInfoPlanOcr() {
      const { count_used_ocr, setting_ocr_unit_price } = this.infoPlanOcr;
      return {
        text_used_ocr: this.$t('ocr_service.label_ocr_used', {
          textColor: count_used_ocr > setting_ocr_unit_price?.usage_limit ? 'red' : '#404D50',
          counter: count_used_ocr,
        }),
        text_max_ocr: this.$t('ocr_service.label_max_ocr', {
          maximum: setting_ocr_unit_price?.usage_limit,
        }),
      };
    },
    disableFormOcrMaxRead() {
      if (this.typeForm === 'view') return false;
      return false;
    },
    disableRead() {
      return (
        this.dataSubmit &&
        this.dataSubmit.template_id === this.formData.template_id &&
        this.dataSubmit.data === this.formData.data
      );
    },
    validateUploadImage() {
      return !this.formData.data || !this.formData.template_id || this.disableRead;
    },
    getPulldownName() {
      return this.energyType.ocr_type === 2
        ? this.$t('ocr_service.label_select_template')
        : this.$t('ocr_service.label_select_ocr_type');
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),

    removeImage() {
      this.isUpdateFile = true;
      this.$emit('onRemoveImage');
    },

    navigateAfterSubmitSuccess() {
      const path = this.existDbSource ? ROUTES.LIST_IDEA_DB_SOURCE : ROUTES.SETTING;
      this.$router.push({ path: path });
    },

    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },

    getIcon(icon) {
      return require(`@/assets/icons/csv-input/${icon}.svg`);
    },
    dragEnter(event) {
      event.preventDefault();
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragLeave() {},

    getFile() {
      const params = {
        file_ocr: {
          pattern_id: this.dataRow.file_ocr.pattern_id,
          unix_time: this.dataRow.file_ocr?.unix_time,
          file_name: this.dataRow.file_ocr?.file_name,
          created_by: this.dataRow.file_ocr?.created_by,
          type: this.formData.template_id !== 'receipt' ? 'template' : 'receipt',
        },
      };
      getFileReadOcr(params).then((res) => {
        if (res.type == 'application/pdf') {
          this.imagePreview = null;
          const filePDF = URL.createObjectURL(res);
          this.$nextTick(() => {
            document.getElementById('pdf-file').src = filePDF;
            PDFObject.embed(filePDF, '#pdf-file', { id: 'pdfFileOcr' });
          });
        } else {
          const image = URL.createObjectURL(res);
          this.imagePreview = image;
        }
      });
    },

    showWarningReadFile() {
      const { count_used_ocr, setting_ocr_unit_price } = this.infoPlanOcr;
      if (count_used_ocr >= setting_ocr_unit_price.usage_limit) {
        this.dialogQuestionUseOcr = true;
      } else {
        this.readFile();
      }
    },

    readFile() {
      this.dialogQuestionUseOcr = false;
      this.dataSubmit = Object.assign({}, this.formData);
      this.$emit('onReadFile');
    },

    handleAddFile(event) {
      if (this.formData.data) {
        this.$refs.csvInput.value = '';
        this.openDialogError();
        return;
      }

      if (event.target.files[0]) {
        const fileName = event.target.files[0].name;
        const fileSize = event.target.files[0].size;
        const fileType = event.target.files[0].type;

        if (
          !this.typeFileUpload.includes(fileType) ||
          this.endsWithIgnoreCase(fileName, '.jfif') ||
          this.endsWithIgnoreCase(fileName, '.jpg')
        ) {
          this.limitSize = true;
          this.messageLimit = this.$t('ocr_service.error_file_type');
          this.dialogLimit = true;
          this.$refs.csvInput.value = '';
          return;
        } else if (fileSize > 5242880) {
          this.limitSize = true;
          this.messageLimit = this.$t('evidence_storage.error_file_size');
          this.dialogLimit = true;
          this.$refs.csvInput.value = '';
          return;
        } else {
          this.csvFilename = fileName;
          const reader = new FileReader();

          if (fileType == 'application/pdf') {
            this.pdfPreview = URL.createObjectURL(event.target.files[0]);
          } else {
            this.imagePreview = URL.createObjectURL(event.target.files[0]);
          }

          this.formData.data = event.target.files[0];
          reader.readAsArrayBuffer(event.target.files[0]);
          this.$refs.csvInput.value = '';
        }
      }
    },
    async dropEvent(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.formData.data) {
        this.$refs.csvInput.value = '';
        this.openDialogError();
        return;
      }
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      const fileType = event.dataTransfer.files[0].type;

      if (!this.typeFileUpload.includes(file.type)) {
        this.limitSize = true;
        this.messageLimit = this.$t('ocr_service.error_file_type');
        this.dialogLimit = true;
        this.$refs.csvInput.value = '';
        return;
      } else if (file?.size > 5242880) {
        this.limitSize = true;
        this.messageLimit = this.$t('evidence_storage.error_file_size');
        this.dialogLimit = true;
        this.$refs.csvInput.value = '';
        return;
      } else {
        this.csvFilename = file.name;
        if (fileType == 'application/pdf') {
          this.pdfPreview = URL.createObjectURL(event.dataTransfer.files[0]);
        } else {
          this.imagePreview = URL.createObjectURL(event.dataTransfer.files[0]);
        }
        this.formData.data = event.dataTransfer.files[0];
        this.$refs.csvInput.value = '';
        // TODO: call api check csv file
      }
    },
    openDialogError() {
      this.messageLimit = this.$t('evidence_storage.error_upload_other_file');
      this.dialogLimit = true;
    },
    handleDeleteFile() {
      this.csvFilename = '';
      this.csvFileData = '';
      this.imagePreview = '';
      this.formData.data = '';
    },
    endsWithIgnoreCase(str, suffix) {
      return str.toLowerCase().endsWith(suffix.toLowerCase());
    },
  },
};
</script>
<style lang="scss">
.listMenu-btn-text {
  .v-text-field.v-text-field--solo {
    .v-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoMid;
    }

    .v-input__slot {
      padding: 0 16px !important;
    }
    .v-input__slot {
      padding: 7px 0px 9px 16px !important;
    }
  }
}
.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}

.show-pdf {
  width: 100%;
  height: 252px;
}

.form-upload-file {
  .label-name {
    span {
      font-family: 'Source Han Sans JP';
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.75px;
    }
  }
}
</style>
<style lang="scss" scoped>
.button-read-file {
  display: flex;
  justify-content: center;
}

.header-form {
  .form-name {
    color: $goldMid;
    font-family: 'Source Han Sans JP';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .label-name {
    color: $monoBlack;
    font-family: 'Source Han Sans JP';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.75px;
  }

  .count-number {
    display: flex;
    gap: 16px;
    background: $bgExtraLight;
    padding: 7px 16px;
    align-items: center;
    .divider-line {
      border-left: solid 1px #99a6a9;
      width: 1px;
      height: 15px;
    }
  }
}
.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}

.listMenu-page {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
  flex: 1;

  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;

    width: 100%;

    .return__header {
      display: none;
    }
  }

  .form-upload-file {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      // padding: 24px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 16px;

      // padding: 8px 8px 8px 12px;
      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }
    }

    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;

      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: auto;
        height: 20px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .title-btn-select-scope {
    margin-bottom: 12px;
  }

  .csv-file {
    background: #f7f7f2;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    margin-top: 10px;
  }

  .csv-input {
    background: $monoOffWhite;
    height: 252px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;

    .import-csv {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 164px;
      width: 100%;
      border: 1px dashed #c8deea;
      padding: 32px 8px;
      gap: 8px;

      .upload-icon {
        width: 50px;
        height: 64px;
      }

      .csv-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .csv-input-text {
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.75px;
          color: $monoMid;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .csv-filename {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $dividerBlack;

    &-detail {
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoBlack;
      word-break: break-all;
      padding: 0px 0px 0px 16px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .remove-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 60px;
      height: 60px;

      &-detail {
        cursor: pointer;
      }
    }
  }

  .csv-download {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    margin-top: 8px;
  }

  .csv-description {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: $monoBlack;
    font-weight: 400;
    margin-top: 8px;
  }
}

@include desktop {
  .listMenu-page {
    .form-upload-file {
      display: grid;
      width: 100%;
      max-width: 560px;
      margin: 0 auto;

      .listMenu-btn {
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;

          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }
      }
    }
  }
}
</style>
