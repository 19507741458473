<template>
  <div class="wrap padding-top-layout">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <h1 class="wrap-title">{{ $t("list_submited.title_application_status") }}</h1>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            />
            <div v-if="showTooltip" class="helper-icon tooltip"
                 v-html='$t("list_submited.tooltip_application_status")'></div>
          </div>
        </div>
      </div>
      <!-- <div class="wrap__block-buttons">
        <common-button
          v-ripple="false"
          type="colored"
          class="btn-action"
          :disabled="isDisableButton"
          @action="dialogApproval = true"
          :label="labelBtnApproved"
        ></common-button>
      </div> -->
    </div>
    <div class="category-table main-table custom-table color-column-status-table submited-table">
      <data-table
        :data-source="listSubmitGrid"
        :grid-columns="listSubmitColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :labelEmpty="descriptionLabelEmpty"
        :isResizeCustome="false"
        class="table-list-data"
        @changeFullScreen="onChangeFullScreen"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
      />
    </div>
    <log-history-popup :dialog="logHistoryPopup" :historyLogList="historyLogList" @close="logHistoryPopup = false" />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
    <approval-popup
      :dialog="dialogApproval"
      :items="listStatusApproval"
      :confirmText="$t('register_data.button_decision')"
      :notificationText="$t('multiple_approval.description_popup_approve', { totalRowsCheckedLength })"
      @submit="approvalDataHandler"
      @close="dialogApproval = false"
    />
    <notification-popup
      :dialog="approvalDialogNotification"
      :message="approvalMessagePopup"
      @submit="approvalDialogNotification = false"
    />
    <!-- <div ref="scrollElement">
      <div class="button-bottom" :class="{ 'fixed-button-bottom': isFixedButton }">
        <common-button
          v-ripple="false"
          type="colored"
          class="btn-action"
          :disabled="isDisableButton"
          @action="dialogApproval = true"
          :label="labelBtnApproved"
        ></common-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import DataTable from "@/components/category/data-table";
import CommentLogPopup from "@/components/dialogs/log-confirm-popup";
import LogHistoryPopup from "@/components/dialogs/history-popup.vue";
import { getListCommentHistory, getListLogHistory, getListWorkflowApi } from "@/api/registerData";
import { getCategoryName, getDataLayer, getScopeName } from "@/utils/registerData";
import { formatDateTimeDataTable } from "@/utils/datetimeFormat";

import { ROUTES } from "@/router/constants";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID, APPROVAL_TYPE } from "@/constants/registerData";
import { mapActions, mapState } from "vuex";
import * as wjcCore from "@mescius/wijmo";
import { CollectionView } from "@mescius/wijmo";
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import * as wjGrid from "@mescius/wijmo.grid";
import { getColorStatusById, getStatusNameById, getColorTextStatusById } from "@/utils/status";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";
import { $_helper_isNumberType, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import { getWidthOfDetailButton, getWidthOfLogHistory } from '@/utils/multiLanguage'
import { getWidthByText } from '@/utils/getWidthByText';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import CommonButton from '@/components/utils/button.vue';
import { updateStatusMultipleData } from '@/api/newRegisterData';
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import ApprovalPopup from '@/components/dialogs/approval-popup';
import { STATUS_FIELD } from "@/constants/status";

export default {
  name: "ListSubmitted",
  components: { DataTable, CommentLogPopup, LogHistoryPopup, CommonButton, NotificationPopup, ApprovalPopup },
  data() {
    return {
      breadCrumb: [
        {
          text: this.$t('list_submited.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD
        },
        {
          text: this.$t('list_submited.label_application_status'),
          disabled: true,
          href: ROUTES.LIST_EMISSION_TEMPORARY
        }
      ],
      listSubmitColumn: [],
      listSubmitGrid: null,
      flexGrid: null,
      listSubmitData: [],
      dialog: false,
      logContents: [],
      showTooltip: false,
      commentPopup: false,
      logHistoryPopup: false,
      commentHistoryList: [],
      historyLogList: [],
      selectedFilterColumn: null,
      isEmptySource: true,
      dialogApproval: false,
      totalRowsCheckedLength: 0,
      listStatusApproval: [],
      selectedItems: [],
      isCheckboxHeaderDisable: true,
      approvalDialogNotification: false,
      approvalMessagePopup: '',
      isFixedButton: true,
      // labelEmpty: '申請中の排出量データはありません。'
    };
  },
  async mounted() {
    this.actionUpdateIsLoadingTable(true)
    await this.updateBreadCrumb(this.breadCrumb);
    await this.handleGetListSubmitData(true);
    this.actionUpdateIsLoadingTable(false)
    this.listStatusApproval = [
      {
        id: APPROVAL_TYPE.APPROVE,
        name: this.$t('register_data.radio_approve'),
        description: this.$t('register_data.description_approve'),
      },
      {
        id: APPROVAL_TYPE.REJECT,
        name: this.$t('register_data.radio_reject'),
        description: this.$t('register_data.description_reject'),
      },
    ];
    // window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoadingTable"]),
    ...mapActions("newRegisterData", ["updateIsApproved"]),
    ...mapActions("registerData", ["updateDataScope", "updateDataCategory", "updateDataMonth", "updateDuration"]),

    initGrid(grid) {
      document.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }

          if (event.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));

                grid.itemsSource.commitNew();
                grid.itemsSource.clearChanges();
              });
            }
          }
        },
        false
      );
      grid.hostElement.addEventListener('mouseover',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      grid.hostElement.addEventListener('mouseout',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
        e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      // add tooltip
      this.flexGrid = grid;
      this.flexGrid.onSelectionChanged(null);
      grid.onSelectionChanged(null);
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      await updateDataBeforeRedirect(rowData.item);
      this.$router.push({ path: `/emissions/register/list-submitted/${rowData.item.id}` });
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.logHistoryPopup = true;
      this.dialogCancelBtn = true;
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.created_at),
          name: commentHistory.user.name,
          content: commentHistory.content
        };
      });
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const listHistoryChanged = await getListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map(listHistoryChanged => {
        return {
          ...listHistoryChanged,
          date: formatDateTimeDataTable(listHistoryChanged.updated_at),
          name: listHistoryChanged.content,
          status: getStatusNameById(listHistoryChanged.status),
          color: getColorStatusById(listHistoryChanged.status)
        };
      });
    },
    async handleGetListSubmitData(isReloadFirstOne) {
      // TODO: call API here
      const payload = {
        action: "listSubmit"
      };
      const listSubmitResponse = await getListWorkflowApi(payload);
      if (listSubmitResponse.data.length > 0) {
        this.listSubmitData = listSubmitResponse.data.map(listSubmitItem => {
          return {
            ...listSubmitItem,
            data_id: listSubmitItem.data_id,
            title: listSubmitItem.title || null,
            status: getStatusNameById(listSubmitItem.status),
            contractor_name: listSubmitItem.contractor?.name,
            log_history: this.$t('list_submited.button_log_display'),
            scope: getScopeName(parseInt(listSubmitItem.scope)),
            category_name: getCategoryName(parseInt(listSubmitItem.scope), parseInt(listSubmitItem.category)) || null,
            layer1: getDataLayer(listSubmitItem.scope, listSubmitItem.category, listSubmitItem.pattern_id)?.method || null,
            layer2: getDataLayer(listSubmitItem.scope, listSubmitItem.category, listSubmitItem.pattern_id)?.sub_method || null,
            date_submit: formatDateTimeDataTable(listSubmitItem.submitted_at),
            month_year_registration: `${listSubmitItem.year}/${String(listSubmitItem.month).padStart(2, "0")}`,
            last_modified: formatDateTimeDataTable(listSubmitItem.updated_at),
            iconComment: listSubmitItem.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : "",
            created_at: formatDateTimeDataTable(listSubmitItem.submitted_at),
            return_at: formatDateTimeDataTable(listSubmitItem.return_at),
            approver: listSubmitItem.selected_approver?.name,
            approved_by: listSubmitItem.approved_by,
            scope_id: parseInt(listSubmitItem.scope),
            category_id: parseInt(listSubmitItem.category),
            duration_id: listSubmitItem.duration_id,
            colorStatus: getColorTextStatusById(listSubmitItem.status),
            status_submit: false,
            is_can_checkbox: listSubmitItem.status === STATUS_FIELD.STATUS_SUBMIT,
          };
        });
      }

      if (isReloadFirstOne) {
        this.defineTable();
      } else {
        this.listSubmitGrid.sourceCollection = this.listSubmitData;
      }
    },
    handleEvent() {
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;

      if (!flexgrid) {
        return;
      }
      const templateResponse = getListWorkflowApi(payload);
      if (templateResponse.data.length > 0) {
        this.listSubmitData = templateResponse.data.map(templateItem => {
          return {
            ...templateItem,
            template_id: templateItem.id,
            scope: getScopeName(parseInt(templateItem.scope)),
            category_name: getCategoryName(parseInt(templateItem.scope), parseInt(templateItem.category)) || null,
            layer1: getDataLayer(templateItem.scope, templateItem.category, templateItem.pattern_id)?.method,
            layer2: getDataLayer(templateItem.scope, templateItem.category, templateItem.pattern_id)?.sub_method,
            last_modified: formatDateTimeDataTable(templateItem.updated_at),
            created_at: formatDateTimeDataTable(templateItem.created_at),
            author: templateItem.submitter?.name,
            approver: templateItem.selected_approver?.name
          };
        });
      }
      this.defineTable();
    },
    initialView() {
      if (this.listSubmitGrid) {
        this.listSubmitData = this.listSubmitGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }

      this.listSubmitGrid = new CollectionView([...this.listSubmitData], {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (this.selectedFilterColumn !== 'category_name') return null;
          const valueA = a?.split('カテゴリ')[1]?.trim();
          const valueB = b?.split('カテゴリ')[1]?.trim();
          if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
            const numA = Number(formatValue(valueA));
            const numB = Number(formatValue(valueB));
            return numA - numB;
          }
          return null;
        }
      });
      this.handleEvent();
      this.flexGrid.columnGroups = this.getListSubmitColumn();
      this.listSubmitGrid.currentItem = null;
      this.flexGrid.cellEditEnded.addHandler(() => {
        const totalRowsChecked = this.getTotalRowsChecked();
        this.totalRowsCheckedLength = totalRowsChecked.length;
      });
      this.flexGrid.formatItem.addHandler((s, e) => {
        if (s.columns[e.col].binding === 'status_submit' && e.panel != s.columnHeaders) {
          const checkLeastOneItemChecked = s.rows.some((row) => row._data.is_can_checkbox);
          if (checkLeastOneItemChecked) {
            this.isCheckboxHeaderDisable = false;
          }
        }

        if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          let rowValue = s.rows[e.row]?._data;
          e.cell.classList.add(rowValue?.colorStatus);
        }

        if (s.columns[e.col].binding === 'status_submit' && e.panel == s.columnHeaders) {
          const checkbox = document.createElement('input');
          checkbox.type = 'checkbox';
          checkbox.classList.add('wj-cell-check-custome');

          if (this.isCheckboxHeaderDisable) {
            wjcCore.toggleClass(e.cell, "wj-state-disabled");
          }

          const self = this;
          checkbox.checked = self.selectAllChecked();
          checkbox.addEventListener('click', (_) => {
            this.flexGrid.beginUpdate();
            this.flexGrid.rows.forEach((row) => {
              if (row.dataItem && row.dataItem['is_can_checkbox']) {
                if (checkbox.checked) {
                  self.selectedItems.push({
                    id: row?.dataItem.id,
                    name: row?.dataItem.name,
                  });
                }
                row.dataItem['status_submit'] = checkbox.checked;
              }
            });
            if (checkbox.checked) {
              self.removeDuplicateData(self.selectedItems);
            } else {
              self.selectedItems = [];
            }
            this.flexGrid.endUpdate();

            const totalRowsChecked = this.getTotalRowsChecked();
            this.totalRowsCheckedLength = totalRowsChecked.length;
          });

          e.cell.appendChild(checkbox);
        }

        if (s.columns[e.col].binding === 'status_submit' && e.panel != s.columnHeaders) {
          const rowData = s.rows[e.row].dataItem;
          const isDisable = !rowData['is_can_checkbox'];
          if (isDisable) {
            wjcCore.toggleClass(e.cell, "wj-state-disabled");
            wjcCore.addClass(e.cell, "wj-cell-background-disabled");
          }
        }

        if (s.columns[e.col].binding === 'data_id' && e.panel != s.columnHeaders) {
          const checkLeastOneItemChecked = s.rows.some((row) => row._data.status_submit);
          if (checkLeastOneItemChecked) {
            wjcCore.toggleClass(e.cell, "wj-state-disabled");
          }
        }
      });
      this.listSubmitGrid.collectionChanged.addHandler((s, e) => {
        this.isCheckboxHeaderDisable = true;
        const checkLeastOneItemChecked = s._view.some((row) => {
          return row.is_can_checkbox;
        });

        if (checkLeastOneItemChecked) {
          this.isCheckboxHeaderDisable = false;
        }
      });
      setMinMaxSizeColumns(this.flexGrid, this.listSubmitData);
      this.markUnreadRows();
    },
    getTotalRowsChecked() {
      const totalRows = this.flexGrid.rows.filter((row) => row.dataItem && row.dataItem['is_can_checkbox']);
      const totalRowsChecked = totalRows.filter((row) => row.dataItem['status_submit'] === true);
      return totalRowsChecked;
    },
    selectAllChecked() {
      const totalRows = this.flexGrid.rows.filter((row) => row.dataItem && row.dataItem['is_can_checkbox']);
      const totalRowsChecked = totalRows.filter((row) => row.dataItem['status_submit'] === true);

      return totalRows.length === totalRowsChecked.length && totalRows.length !== 0 && totalRowsChecked.length !== 0
        ? true
        : false;
    },
    removeDuplicateData(arr) {
      this[arr] = [...new Map(arr.map((item) => [item['id'], item])).values()];
    },
    markUnreadRows() {
      this.flexGrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = this.listSubmitGrid.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread = currentItem !== undefined && currentItem["unread_flg"] !== undefined ? currentItem["unread_flg"] : 0;
          if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
            if (currentItemUnread) {
              eventHandler.cell.classList.add("wj-has-unread-mark");
            }
          }
        }
      });
    },
    addBlankItemsToView(count) {
      const lastClientId = this.listSubmitGrid.itemCount;
      for (let index = 1; index <= count; index++) {
        this.listSubmitGrid.addNew(this.blankData(lastClientId + index));
      }

      this.listSubmitGrid.commitNew();
      this.listSubmitGrid.clearChanges();
    },
    getListSubmitColumn() {
      return [
        {
          header: "#",
          binding: "id",
          allowSorting: false,
          isReadOnly: true,
          visible: false
        },
        {
          header: " ",
          binding: "data_id",
          minWidth: getWidthOfDetailButton(),
          maxWidth: getWidthOfDetailButton(),
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: "template-detail-header",
          cellTemplate: CellMaker.makeButton({
            text: this.$t('list_submited.button_detail'),
            click: (event, context) => this.onIdClicking(context)
          })
        },
        // {
        //   header: " ",
        //   binding: "status_submit",
        //   width: 40,
        //   dataType: 'Boolean',
        //   maxWidth: 350,
        //   allowSorting: false,
        //   isRequired: false,
        //   multiLine: true,
        //   wordWrap: true,
        //   cssClassAll: "height34 submitted-cell_status-submit hide-filter"
        // },
        {
          header: this.$t('list_submited.table_status'),
          binding: "status",
          minWidth: getWidthByTextContent(this.$t('list_submited.table_status')),
          maxWidth: 350,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cellTemplate: (ctx) => (`<span class="${ctx.item.colorStatus}">${ctx.item.status}</span>`),
        },
        {
          header: " ",
          binding: "iconComment",
          minWidth: 32,
          maxWidth: 32,
          allowSorting: false,
          isRequired: false,
          cssClass: "btn-db",
          cellTemplate: CellMaker.makeImage({
            click: (event, context) => this.onCommentClicking(context)
          }),
          cssClassAll: "approval-cell_comment hide-filter comment-icon",
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_title'),
          binding: "title",
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_created_at'),
          binding: "created_at",
          minWidth: getWidthByTextContent(this.$t('list_submited.table_created_at')) + 5,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_return_at'),
          binding: "return_at",
          minWidth: getWidthByTextContent(this.$t('list_submited.table_return_at')) + 5,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_approver'),
          binding: "approver",
          minWidth: getWidthByTextContent(this.$t('list_submited.tabltable_approvere_status')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_contractor_name'),
          binding: "contractor_name",
          minWidth: getWidthByTextContent(this.$t('list_submited.table_contractor_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_scope'),
          binding: "scope",
          minWidth: getWidthByTextContent(this.$t('list_submited.table_scope')) + 5,
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: "",
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_category'),
          binding: "category_name",
          minWidth: getWidthByTextContent(this.$t('list_submited.table_category')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_calculation_method'),
          align: "center",
          columns: [
            {
              header: this.$t('list_submited.table_layer1'),
              binding: "layer1",
              minWidth: getWidthByTextContent(this.$t('list_submited.table_layer1')) + 5,
              maxWidth: 890,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true
            },
            {
              header: this.$t('list_submited.table_layer2'),
              binding: "layer2",
              minWidth: getWidthByTextContent(this.$t('list_submited.table_layer2')) + 5,
              maxWidth: 890,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true
            }
          ]
        },
        {
          header: this.$t('list_submited.table_month_year_registration'),
          binding: "month_year_registration",
          minWidth: getWidthByTextContent(this.$t('list_submited.table_month_year_registration')),
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.table_last_modified'),
          binding: "last_modified",
          minWidth: getWidthByTextContent(this.$t('list_submited.table_last_modified')),
          maxWidth: 300,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_submited.button_log_history'),
          binding: "log_history",
          minWidth: getWidthByText(this.$t('list_submited.button_log_history')),
          width: '*',
          allowSorting: false,
          isRequired: false,
          cssClassAll: "submit-log show-log-btn",
          cellTemplate: CellMaker.makeButton({
            text: this.$t('list_submited.button_log_display'),
            click: (e, ctx) => this.onHistoryClicking(ctx)
          })
        }
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    defineTable() {
      this.listSubmitColumn = this.getListSubmitColumn();
      this.initialView();
      this.isEmptySource= this.listSubmitData.length <= 0;
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip = true;
      }
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    async approvalDataHandler(approvalFormData) {
      const ACTION = {
        approve: 'approve',
        cancelSubmit: 'cancel_submit',
      };
      const totalRowsChecked = this.getTotalRowsChecked();
      const workflowIds = totalRowsChecked.map((row) => row._data.id);

      const payload = {
        action: approvalFormData.status === APPROVAL_TYPE.APPROVE ? ACTION.approve : ACTION.cancelSubmit,
        workflowIds,
        selected_approver: '',
        comment: approvalFormData.comment,
        check_duplicate: 1,
      };

      try {
        const response = await updateStatusMultipleData(payload);
        if (response.success) {
          this.dialogApproval = false;
          this.selectedItems = [];
          this.totalRowsCheckedLength = 0;
          this.isCheckboxHeaderDisable = true;

          this.actionUpdateIsLoadingTable(true);
          await this.handleGetListSubmitData();
          this.actionUpdateIsLoadingTable(false);

          this.approvalDialogNotification = true;
          this.approvalMessagePopup = this.$t('facility_management.message_saved_changes');
        }
      } catch (error) {
        this.dialogApproval = false;
        this.approvalDialogNotification = true;
        this.approvalMessagePopup = error.message;
      }
    },
    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement.offsetTop;
      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > scrollElementTop + 239) {
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },
  },
  computed: {
    ...mapState("commonApp", ["isExpand"]),
    ...mapState("registerData", ["isFullScreen"]),

    descriptionLabelEmpty() {
      return this.$t("list_submited.description_label_empty");
    },
    labelBtnApproved() {
      return this.$t("multiple_approval.button_approved", { totalRowsCheckedLength: this.totalRowsCheckedLength });
    },
    isDisableButton() {
      return this.totalRowsCheckedLength === 0;
    },
  }
};
</script>


<style lang="scss">
.wj-flexgrid .wj-cell-check-custome[type=checkbox]:checked {
  line-height: 1em;
}

.wj-flexgrid .wj-cell-background-disabled {
  background-color: $monoLight !important;
}

.submited-table {
  .template-detail-header {
    padding: 2px 4px !important;

    .wj-btn {
      display: none;
    }
  }

  .wj-flexgrid .wj-cell[aria-readonly] {
    // background: #F7F7F2 !important;
    color: #404d50 !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].template-detail-header {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].submit-log {
    padding: 4px !important;
  }

  // .wj-flexgrid .wj-cell[aria-readonly].wj-state-active {
  //   background: #F7F7F2 !important;
  // }

  .wj-flexgrid .wj-cell-maker {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #404D50;
    // font-family: 'Source Han Sans JP';
    padding: 0;
  }

  .wj-cell {
    .wj-cell-maker {
      justify-content: start;
    }

    .wj-cell-maker:hover {
      color: #ffffff;
      background: #0072a3;
    }

  }

  .template-detail-header {
    .wj-cell-maker {
      // margin-top: 2px;
      font-style: normal;
      font-family: 'Source Han Sans';
      width: 100%;
      color: $goldMid !important;
      position: unset !important;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 1px solid #A4A184;
    }

    .wj-cell-maker:hover {
      color: #ffffff !important;
      background: #0072a3;
      button {
        color: #ffffff;
      }
    }
  }

  .hide-filter {
    .wj-btn {
      display: none;
    }
  }

  .submit-log.wj-cell {
    .wj-btn {
      display: none;
    }

    .wj-cell-maker {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1px 6px 3px;
      background: #E4E6E7;
      font-family: 'Source Han Sans';
      border-radius: 4px;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: #404D50 !important;
      min-width: 64px;
      min-height: 24px;
      position: unset !important;
    }

    .wj-cell-maker:hover {
      color: #ffffff !important;
      background: #0072a3;
      button {
        color: #ffffff;
      }
    }
  }

  .approval-pending {
    color: #0072A3;
  }

  .approval-send-back {
    color: $redMid;
  }

  .approval-complete {
    color: #99A6A9;
  }
}
</style>
<style scoped lang="scss">
.button-bottom {
  width: calc(100% + 40px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px;
  background: $bgMid;
  box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
  position: relative;
  bottom: -80px;
  margin-left: -20px;

  &.fixed-button-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 16px 40px;
  }
  .btn-action {
    width: 100%;
    justify-content: center;
  }
}
@include desktop {
  .button-bottom {
    display: none;
  }
}

.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}

.wrap {
  width: 100%;
  height: 100%;

  &__block-buttons {
    display: flex;
    gap: 20px;
    margin-right: 40px;

    .btn-action {
      width: fit-content;
      min-width: 180px;
    }
  }

  .wrap__block__header__content {
    display: flex;
    align-items: center;
    // tooltip icon
    .helper-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: relative;

      img {
        z-index: 1;
      }

      &.tooltip {
        display: block;
        justify-content: left;
        background: $monoWhite;
        border-radius: 4px;
        color: $monoBlack;
        margin: 0 auto;
        font-size: 15px;
        padding: 10px;
        height: unset;
        // width: 315px;
        max-width: max-content;
        // position: absolute;
        right: unset;
        left: 0px;
        bottom: unset;
        z-index: 9999;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
        0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
        0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        position: fixed;
        width: 100%;
      }
    }
  }

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: flex;
      align-items: center;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }
  }

  &__header {

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    padding: 0 0 40px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    @media (max-width: 1023px) {
      &-buttons {
        display: none;
      }
    }

    &__header {
      flex: 1 0;
      @media (max-width: 1023px) {
        padding-top: 40px;
      }

      &__content {
        flex-direction: row;
      }

      span, .wrap-title {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }
  }
}

@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }
  .return {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }
  .wrap__block {
    margin-left: 40px;
  }
  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}
</style>
