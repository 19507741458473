import * as wjGrid from '@mescius/wijmo.grid';
import store from '@/store';
import { prepareMasterOrCustomizeDB, getDbCustomizeOrDbMasterById } from '@/concerns/utils/master-or-customize-db'
import { AutoComplete } from '@mescius/wijmo.input';
import { KEYS_CODE } from '@/constants/keyboard'
import {
	BLANK_ID,
	MESSAGE_NOT_NULL,
	MESSAGE_MAX_20_CHARACTOR,
	MESSAGE_IS_NUMBER,
} from '@/constants/registerData';
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import debounce from 'lodash/debounce'
import { formatValue, $_helper_isNumberType, math, formatBigNumber, formatEmission25Number, validateNumberField } from '../wijmo.helper'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import { addThoundSandComma } from '@/utils/convertNumber';
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { GICS_PULLDOWN, SCOPE_TYPE, CURRENCY_TYPE, SCORE_TYPE, GROUP_COMPANY_KEY } from '@/constants/registerData';
import { handlerFilterGroupBusinessData, handlerAddGroupBusinessFilterData, handleGetBusinessGroupId, addGroupBusinessFilterByPasted } from '@/concerns/utils/business-groups';
import { getWidthByTextContent } from '@/utils/calcTextWidth';
import { toolTipCustom } from "@/utils/tooltipCustom";
import { getWidthOfAttachedButton, getWidthButtonByText } from '@/utils/multiLanguage';
export const blankData = (clientRowId) => {
	return {
		clientRowId: clientRowId,
		id: BLANK_ID,
		organizational_division: null,
		business_name: null,
		company_name: null,
		ie_year: null,
		emissions: null,
		duration_id: null,
		month: null,
		year: null,
		type: null,
		memo_1: null,
		memo_2: null,
		country: null,
		layer_3: null,
		layer_4: null,
		layer_5: null,
		layer_6: null,
		classify_gics: null,
		scope: null,
		category1: null,
		amount: null,
		currency: null,
		category2: null,
		gdp_ppp_adjusted: null,
		government_bond_emissions: null,
		business_group_id: null,
    pcaf_business_group_name: null,
    pcaf_business_name: null,
		loan_amount: null,
		dq_quality_score: null,
	}
}

const isZeroValue = value => {
	return ['0.00', '0', 0, null, '', 'null'].includes(value)
}
const calcEmissions = data => {
	const { loan_amount, gdp_ppp_adjusted, government_bond_emissions, isLimitedAttributionFactor } = data
	const arrayKeyToCalcEmissions = ['loan_amount', 'gdp_ppp_adjusted', 'government_bond_emissions']

	const isAllItemIsNumber = arrayKeyToCalcEmissions.every(key => $_helper_isNumberType(data[key])) // check number all value
	if (isAllItemIsNumber) {
		// define value for bignumber      
		const val_amount = math.bignumber(formatValue(loan_amount));
		const val_gdp_ppp_adjusted = math.bignumber(formatValue(gdp_ppp_adjusted));
		const val_government_bond_emissions = math.bignumber(formatValue(government_bond_emissions));

		const emissionVal = formatBigNumber(math.evaluate(`${val_amount.toString()} / ${val_gdp_ppp_adjusted.toString()} * ${val_government_bond_emissions.toString()}`), 25)
    if(isLimitedAttributionFactor) { //case attribution_factor from 0 => 100
			const isOnlyAmountSmallerZero = math.smaller(val_amount, '0') && !math.smaller(val_gdp_ppp_adjusted, '0')
      const isOnlyTotalCapitalSmallerZero = !math.smaller(val_amount, '0') && math.smaller(val_gdp_ppp_adjusted, '0')
      const isAttributionSmallerZeroPercent = isOnlyAmountSmallerZero || isOnlyTotalCapitalSmallerZero

      // const isAttributionSmallerZeroPercent = math.smaller(val_amount, '0') || math.smaller(val_gdp_ppp_adjusted, '0')
      const isAttributionLarger100Percent = math.larger(val_amount, val_gdp_ppp_adjusted)
      if(isAttributionSmallerZeroPercent) {
				return '0.00';
      } else if(isAttributionLarger100Percent) {
        return formatBigNumber(math.evaluate(`1 * ${val_government_bond_emissions.toString()}`), 25)
      } else {
        return emissionVal
      }
    } else {
      return emissionVal
    }
	}
	return '0.00';
}

const notNullList = [
	'organizational_division',
	'company_name',
	// 'pcaf_business_group_name',
	// 'pcaf_business_name',
	'classify_gics',
	'scope',
	'loan_amount',
	'gdp_ppp_adjusted',
	'government_bond_emissions',
	'scope',
	'currency'
]

const max25Charactor = [
	'loan_amount',
	'gdp_ppp_adjusted',
	'government_bond_emissions'
]

const max128Charactor = [
	'company_name',
	// 'business_name',
	'scope',
	'gics_value',
	'memo_1',
	'memo_2',
	'category1',
	'category2',
]

const numberField = [
	'loan_amount',
	'gdp_ppp_adjusted',
	'government_bond_emissions'
]
const plusWidthMark = 70;
let isReverted = false;
let isBusinessGroupReverted = false;
const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']

const handleAutoFillDefaultValue = dataBinding => {
	const { s, binding, row, currentItem, typeOfPattern, isDeleteTing } = dataBinding

	if (!isDeleteTing) {
		if(!currentItem?.currency ) {
		  s.setCellData(row, getColumnIndexByBinding(s, 'currency'),  CURRENCY_TYPE[0], false, true)
		}
		if(!currentItem?.dq_quality_score ) {
		  s.setCellData(row, getColumnIndexByBinding(s, 'dq_quality_score'),  getScoreByType(typeOfPattern.dataTypeIdSelect,typeOfPattern)[0], false, true)
		}
	  }
}

const autoBindingDbSource = dataBinding => {
	const { s, binding, row, currentItem, isLimitedAttributionFactor } = dataBinding
	handleAutoFillDefaultValue(dataBinding)
	if (['loan_amount', 'gdp_ppp_adjusted', 'government_bond_emissions', 'classify_gics'].includes(binding)) {
		s.deferUpdate(() => {
			const dataForCalcEmissions = {
				loan_amount: currentItem.loan_amount,
				gdp_ppp_adjusted: currentItem.gdp_ppp_adjusted,
				government_bond_emissions: currentItem.government_bond_emissions,
				isLimitedAttributionFactor
			}
			s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), calcEmissions(dataForCalcEmissions), false, true)
		})
	}
}
const getScoreByType = (typeData, typeOfPattern) => {
	if(!typeData) return [null, null]
  
	let valueDataType2 = null
	let dq_quality_score = '1'
	switch (typeData) {
	  case typeOfPattern.itemsDataType[0]['id']:
		dq_quality_score = '1'
		break;
	  case typeOfPattern.itemsDataType[1]['id']:
		dq_quality_score = '2'
		break;
	  case typeOfPattern.itemsDataType[2]['id']:
		dq_quality_score = '3'
		break;
	  case typeOfPattern.itemsDataType[3]['id']:
		dq_quality_score = '4'
		break;
	  case typeOfPattern.itemsDataType[4]['id']:
		dq_quality_score = '5'
		break;
	  default:
		dq_quality_score = '5'
		break;
	}
	return [dq_quality_score, valueDataType2]
  }
let filterIndex = {};
const parternS3c15pcaf7 = {
	initializeGrid(flexgrid, itemCount, branchData = {}, dbStore, businessGroupList, isLimitedAttributionFactor = false, isRemoveEmissionFactorOrValNumber = false, typeOfPattern) {
		let selectedFirst = null;
		let previousCellData = null;
		let checkFilterDataAfterPasted = {};
		let filterBusinessGroupIndex = {};
    let selectedBusinessGroupFirst = null;
    let previousBusinessGroupCellData = null;
		let checkBusinessGroupFilterDataAfterPasted = {};

		flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
			if (!store.state.registerData.isFullScreen) {
				return
			}

			if (s.viewRange.bottomRow >= s.rows.length - 1) {
				s.deferUpdate(() => {
					const lastClientId = flexgrid.itemsSource.itemCount

					for (let index = 1; index <= itemCount; index++) {
						s.itemsSource.addNew(blankData(lastClientId + index));
					}

					s.itemsSource.commitNew();
					s.itemsSource.clearChanges();
				});
			}
		}, 100))

		document.addEventListener('keydown', (e) => {
			if (
				(e.metaKey || e.ctrlKey) &&
				[KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
			) {
				e.preventDefault();
			}
		});

		flexgrid.hostElement.addEventListener('keydown', (e) => {
			if (e.metaKey || e.ctrlKey) {
				if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
					const currentSelection = flexgrid.selection
					const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
					flexgrid.selection = cellRange

					// re-select after add more
					setTimeout(() => {
						flexgrid.selection = cellRange
					}, 200);
				} else if (e.keyCode === KEYS_CODE.UP_ARROW) {
					const currentSelection = flexgrid.selection
					const cellRange = new wjGrid.CellRange(0, currentSelection.col)
					flexgrid.selection = cellRange
				} else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
					const currentSelection = flexgrid.selection
					const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
					flexgrid.selection = cellRange
				} else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
					const currentSelection = flexgrid.selection
					const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
					flexgrid.selection = cellRange
				}
			}
			if (e.keyCode === KEYS_CODE.ENTER) {
				if (flexgrid.selection.row === flexgrid.rows.length - 1) {
					const lastClientId = flexgrid.itemsSource.itemCount

					flexgrid.deferUpdate(() => {
						flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

						flexgrid.itemsSource.commitNew();
						// flexgrid.itemsSource.clearChanges();
					});
				}
			}

		}, false)

		flexgrid.pasted.addHandler((s, e) => {
			const { col, col2, row, row2 } = e.range
			const view = s.collectionView
			const source = view.sourceCollection
			const dataStore = getDbCustomizeOrDbMasterById(dbStore.dbCustomizes)

			for (let colIndex = col; colIndex <= col2; colIndex++) {
				for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
					const cellData = s.getCellData(rowIndex, colIndex, false)
					const currentItem = source[rowIndex] || {}
					const binding = s.columns[colIndex].binding
					const dataBinding = { s, binding, row: rowIndex, cellData, currentItem, dataStore, isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber, typeOfPattern }

					// add filter index after pasted
					if (filterColumns.includes(s.columns[colIndex].binding)) {
						if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
							addFilterByPasted(s, e, colIndex, rowIndex, filterIndex);
							if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
								checkFilterDataAfterPasted[rowIndex] = {};
							}
							checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
						}
					}
					if (['loan_amount', 'gdp_ppp_adjusted', 'government_bond_emissions', 'classify_gics'].includes(s.columns[colIndex].binding)) {
						autoBindingDbSource(dataBinding)
					}
					handleAutoFillDefaultValue(dataBinding)

					if (GROUP_COMPANY_KEY.includes(s.columns[colIndex].binding)) {
						if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
							addGroupBusinessFilterByPasted(s.columns[colIndex], filterBusinessGroupIndex, rowIndex)
							if (typeof checkBusinessGroupFilterDataAfterPasted[rowIndex] === 'undefined') {
								checkBusinessGroupFilterDataAfterPasted[rowIndex] = {};
							}
							checkBusinessGroupFilterDataAfterPasted[rowIndex] = source[rowIndex];
						}
					}
				}
			}
			if (Object.values(checkFilterDataAfterPasted).length > 0) {
				const errorMessage = checkFilterData(branchData.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
				store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
				checkFilterDataAfterPasted = {}
			}
		})

		flexgrid.cellEditEnded.addHandler((s, e) => {
			let column = s.columns[e.col];
			const { row, col } = e.range
			const cellData = s.getCellData(row, col, false)
			const view = s.collectionView
			const source = view.sourceCollection
			const currentItem = source[row] || {}
			const dataStore = getDbCustomizeOrDbMasterById(dbStore.dbCustomizes)
			const item = s.rows[e.row].dataItem;
			const isDeleteTing = e.data && (e.data.key === 'Backspace' || e.data.key === 'Delete')
			const binding = column.binding
			const dataBinding = { s, binding, row, cellData, currentItem, dataStore, isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber, typeOfPattern, isDeleteTing }
			autoBindingDbSource(dataBinding)

			if (filterColumns.includes(column.binding)) {
				handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirst, previousCellData, isReverted);
			}

			if (GROUP_COMPANY_KEY.includes(column.binding)) {
        handlerFilterGroupBusinessData({s, e}, businessGroupList['item'], filterBusinessGroupIndex, selectedBusinessGroupFirst, previousBusinessGroupCellData, isBusinessGroupReverted)
      }
		});

		flexgrid.beginningEdit.addHandler((s, e) => {
			let column = s.columns[e.col];
      let item = s.rows[e.row].dataItem;

			if (['emissions'].includes(column.binding)) {
				e.cancel = true;
			}

			const { row, col } = e.range
			const cellData = s.getCellData(row, col, false)
			const view = s.collectionView
			const source = view.sourceCollection
			const currentItem = source[Number(item?.clientRowId) - 1 || row] || source[row] || {}

			if (filterColumns.includes(column.binding)) {
				previousCellData = cellData;
				handlerAddFilterData(s, e, filterIndex, selectedFirst, store.state.registerData, branchData);
			}

			if (GROUP_COMPANY_KEY.includes(column.binding)) {
        previousBusinessGroupCellData = cellData;
        handlerAddGroupBusinessFilterData({s, e}, businessGroupList['item'], filterBusinessGroupIndex, selectedBusinessGroupFirst)
      }
		});

		flexgrid?.formatItem.addHandler((s, e) => {
      const colBinding = e.panel.columns[e.col].binding;

      if (e.panel === s.columnHeaders) {
				if (colBinding === "pcaf_business_group_name" && e.cell.innerText?.split('\n')[0] === i18n.t('56_pattern.table_group_company')) {
					toolTipCustom(
						e,
						"t1",
						i18n.t('56_pattern.tooltip_group_company'), //todo: update text
						dbStore.hdrTips
					);
				}

				if (colBinding === "loan_amount") {
					toolTipCustom(
						e,
						"t3",
						i18n.t('56_pattern.tooltip_currency'), //todo: update text
						dbStore.hdrTips
					);
				}
			}
  	});
	},

	header(registerData, listLayout, branchData, externalSourcePopup, attachFilePopup, serviceLinkPopup, dbStore, grid, isRemoveStatusColumn, businessGroupList, readFileOcrDialog, isRemoveEmissionFactorOrValNumber, typeOfPattern) {
		let companyMap = new wjGrid.DataMap(branchData.company_name, 'value', 'value');
		companyMap.getDisplayValues = (dataItem) => {
			let validCompany = branchData?.company_name?.filter(
				(company) => company.organizational_division === dataItem?.organizational_division,
			);
			return validCompany.map((item) => {
				return {
					value: item.value,
				};
			});
		};

		companyMap.getDisplay = (dataItem) => {
			let validCompany = branchData?.company_name?.filter(
				(company) => company.organizational_division === dataItem,
			);
			return validCompany.map((item) => {
				return {
					value: item.value,
				};
			});
		};

		let businessMap = new wjGrid.DataMap(branchData.business_name, 'value', 'value');
		businessMap.getDisplayValues = (dataItem) => {
			let validBusiness = branchData?.business_name?.filter(
				(company) => company.company_name === dataItem?.company_name,
			);

			let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
			return uniqueItem.map((item) => {
				return {
					value: item.value,
					key: item.value,
				};
			});
		};

		businessMap.getDisplay = (dataItem, organizational_division) => {
			let validBusiness = branchData?.business_name?.filter(
				(business) => business.company_name === dataItem && business.organizational_division === organizational_division,
			);

			let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
			return uniqueItem.map((item) => {
				return {
					value: item.value,
					key: item.value,
				};
			});
		};

		const current_emissions_type = typeOfPattern.itemsDataType.findIndex(item => item.id === typeOfPattern.dataTypeIdSelect);
    const item_select = typeOfPattern.itemsDataType[current_emissions_type]
    let name_emissions_type_col = item_select?.name;

		return [
			{
				header: '連番',
				binding: 'id', // id
				minWidth: 40,
				maxWidth: 65,
				allowSorting: false,
				isReadOnly: true,
				visible: false,
				isRequired: false,
			},
			{
				header: i18n.t('56_pattern.table_organizational_division'),
				binding: 'organizational_division',
				minWidth: 96,
				maxWidth: 980,
				allowResizing: true,
				allowSorting: false,
				wordWrap: true,
				isRequired: false,
				dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
				cssClassAll: 'required-field',
				editor: new AutoComplete(document.createElement('div'), {
					placeholder: i18n.t('56_pattern.placeholder'),
					itemsSource: registerData.listType,
					selectedValuePath: 'key',
					displayMemberPath: 'value',
					maxItems: 1000,
					minLength: 1,
					selectedIndex: -1,
				}),
			
			},
			{
				header: i18n.t('56_pattern.table_company_name'),
				binding: 'company_name',
				minWidth: 85,
				maxWidth: 980,
				allowSorting: false,
				isRequired: false,
				wordWrap: true,
				dataMap: companyMap,
				cssClassAll: 'required-field',
				editor: new AutoComplete(document.createElement('div'), {
					placeholder: i18n.t('56_pattern.placeholder'),
					itemsSource: branchData?.company_name,
					selectedValuePath: 'value',
					displayMemberPath: 'value',
					maxItems: 1000,
					minLength: 1,
					selectedIndex: -1,
				}),
			},
			...listLayout,
			{
				header: i18n.t('56_pattern.table_destination'),
				align: 'center',
				columns: [
					{
            header: i18n.t('56_pattern.table_group_company'),
            binding: 'pcaf_business_group_name', //ten doanh nghiep
						width: getWidthByTextContent(i18n.t('56_pattern.table_group_company'), 30),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true,
            dataMap: businessGroupList['pcaf_business_group_name'],
						// cssClassAll: 'required-field',
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: businessGroupList['pcaf_business_group_name'],
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
          {
            header: i18n.t('56_pattern.table_company'),
            binding: 'pcaf_business_name', // todo: update to pull down
            minWidth: 70,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true,
            dataMap: businessGroupList['pcaf_business_name'],
						// cssClassAll: 'required-field',
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: businessGroupList['pcaf_business_name'],
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
				],
			},
			{
				header: i18n.t('56_pattern.table_gics_value'),
				binding: 'classify_gics',
				minWidth: 70,
				maxWidth: 980,
				allowSorting: false,
				isRequired: false,
				wordWrap: true,
				multiLine: true,
				cssClassAll: 'required-field',
				dataMap: new wjGrid.DataMap(GICS_PULLDOWN, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: GICS_PULLDOWN,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
			},
			{
				header: i18n.t('56_pattern.table_memo_1'),
				binding: 'memo_1',
				minWidth: 90,
				maxWidth: 980,
				allowSorting: false,
				wordWrap: true,
				isRequired: false,
				multiLine: true
			},
			{
				header: i18n.t('56_pattern.table_memo_2'),
				binding: 'memo_2',
				minWidth: 90,
				maxWidth: 980,
				allowSorting: false,
				wordWrap: true,
				isRequired: false,
				multiLine: true
			},
			{
				header: i18n.t('56_pattern.table_scope'),
				binding: 'scope',
				minWidth: 70,
				maxWidth: 980,
				allowSorting: false,
				isRequired: false,
				wordWrap: true,
				multiLine: true,
				cssClassAll: 'required-field',
				dataMap: new wjGrid.DataMap(SCOPE_TYPE),
				editor: new AutoComplete(document.createElement('div'), {
					placeholder: i18n.t('56_pattern.placeholder'),
					itemsSource: SCOPE_TYPE,
					maxItems: 1000,
					minLength: 1,
					selectedIndex: -1,
				}),
			},
			{
				header: i18n.t('56_pattern.table_attraction_factor'), //Attribution factor
				align: 'center',
				columns: [
					{
						header: i18n.t('56_pattern.table_category1'),
						binding: 'category_1',
						minWidth: 160,
						maxWidth: 980,
						allowSorting: false,
						isRequired: false,
						wordWrap: true,
					},
					{
						header: i18n.t('pcaf_update.table_exposure_to_sovereign_bond'),
						binding: 'loan_amount',
						width: getWidthButtonByText(i18n.t('pcaf_update.table_exposure_to_sovereign_bon'), { fontSize: '13px', fontWeight: 500, letterSpacing: '0.04em', lineHeight: 20 }) + plusWidthMark,
						allowSorting: false,
						isRequired: false,
						wordWrap: true,
						cssClassAll: 'required-field',
						align: 'right',
					},
					{
						header: i18n.t('56_pattern.table_currency'),
						binding: 'currency',
						minWidth: 140,
						maxWidth: 980,
						allowSorting: false,
						isRequired: false,
						wordWrap: true,
						cssClassAll: 'required-field',
						dataMap: new wjGrid.DataMap(CURRENCY_TYPE),
						editor: new AutoComplete(document.createElement('div'), {
							placeholder: i18n.t('56_pattern.placeholder'),
							itemsSource: CURRENCY_TYPE,
							maxItems: 1000,
							minLength: 1,
							selectedIndex: -1,
						}),
					},
					{
						header: i18n.t('56_pattern.table_category1'),
						binding: 'category_2',
						minWidth: 160,
						maxWidth: 980,
						allowSorting: false,
						isRequired: false,
						wordWrap: true,
					},
					{
						header: i18n.t('56_pattern.table_purchasing_power_parity'),
						binding: 'gdp_ppp_adjusted',
						minWidth: 140,
						maxWidth: 980,
						allowSorting: false,
						isRequired: false,
						wordWrap: true,
						cssClassAll: 'required-field',
						align: 'right',
					},
				],
			},
			{
				header: i18n.t('56_pattern.table_le_value'),
				align: 'center',
				columns: [
					{
						header: name_emissions_type_col,
						binding: 'government_bond_emissions',
						minWidth: getWidthByTextContent(name_emissions_type_col),
						maxWidth: 1200,
						cssClassAll: 'required-field',
						allowSorting: false,
						isRequired: false,
						wordWrap: true,
						align: 'right',
					},
				],
			},
			{
				header: i18n.t('56_pattern.summary_score_dq_quality_score'),
				binding: 'dq_quality_score',
				minWidth: getWidthByTextContent(i18n.t('56_pattern.summary_score_dq_quality_score')),
				maxWidth: 980,
				allowSorting: false,
				isRequired: false,
				wordWrap: true,
				dataMap: new wjGrid.DataMap(SCORE_TYPE),
				editor: new AutoComplete(document.createElement('div'), {
					placeholder: i18n.t('56_pattern.placeholder'),
					itemsSource: SCORE_TYPE,
					maxItems: 1000,
					minLength: 1,
					selectedIndex: -1,
				}),
			},
			{
				header: i18n.t('56_pattern.table_emissions'),
				binding: 'emissions',
				minWidth: 210,
				maxWidth: 980,
				allowSorting: false,
				isRequired: false,
				wordWrap: true,
				cssClass: 'auto-increment',
        cssClassAll: 'emission',
				align: 'right',
			},
			{
				header: i18n.t('56_pattern.table_attach_file'),
				binding: "attach_file",
				cssClass: 'auto-increment',
				minWidth    : getWidthOfAttachedButton(i18n.locale),
        maxWidth    : getWidthOfAttachedButton(i18n.locale),
				allowSorting: false,
				isRequired: false,
				cssClassAll: "btn-db attach-file hide-filter",
				cellTemplate: (ctx, el) => {
					let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
					const button = CellMaker.makeButton({
						text: text,
						click: (e, context) => attachFilePopup(e, context)
					})
					return button(ctx, el);
				}
			},
			{
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired  : false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
		];
	},

	handlerDataOnTable(dataTable, bindingLayout, isSaveTemplate = false, branchData, isGetBranchDetail = true, dbStore, isLimitedAttributionFactor = false, isRemoveEmissionFactorOrValNumber, typeOfPattern, isDeleteTingEvent) {
		const valueAutofill = getScoreByType(typeOfPattern?.dataTypeIdSelect,typeOfPattern)
		if(!dataTable?.dq_quality_score && !isDeleteTingEvent && !dataTable.isDeleteRow) {
			dataTable.dq_quality_score = valueAutofill[0]
		}
		if (!isGetBranchDetail) return
		const dataForCalcEmissions = {
			loan_amount: dataTable.loan_amount,
			gdp_ppp_adjusted: dataTable.gdp_ppp_adjusted,
			government_bond_emissions: dataTable.government_bond_emissions,
			isLimitedAttributionFactor
		}
		const emissions = calcEmissions(dataForCalcEmissions);

		const layoutsData = {}
		bindingLayout.forEach(key => {
			layoutsData[key] = dataTable[key] || null
		})
		const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
			organizational_division: dataTable.organizational_division,
			company_name: dataTable.company_name,
			...layoutsData
		}) : {};

		if (typeof dataTable.evidence === 'string') {
			dataTable.evidence = JSON.parse(dataTable.evidence);
		}

		const businessGroupId = handleGetBusinessGroupId(dataTable.pcaf_business_name, dataTable.pcaf_business_group_name, dbStore.getGroupCompanyObj)
	
		let dataTableItemCustom = {
			id: dataTable.id,
			company_name: dataTable.company_name,
			organizational_division: dataTable.organizational_division,
			emissions: formatEmission25Number(emissions),
			classify_gics: dataTable.classify_gics || null,
			scope: dataTable.scope || null,
			memo_1: dataTable.memo_1 || null,
			memo_2: dataTable.memo_2 || null,
			category_1: dataTable.category_1 || null,
			category_2: dataTable.category_2 || null,
			loan_amount: isSaveTemplate ? '' : formatValue(dataTable.loan_amount) === 0 ? '0' : formatValue(dataTable.loan_amount) || null,
			gdp_ppp_adjusted: isSaveTemplate ? '' :  formatValue(dataTable.gdp_ppp_adjusted) === 0 ? '0' : formatValue(dataTable.gdp_ppp_adjusted) || null,
			government_bond_emissions: isSaveTemplate ? '' :  formatValue(dataTable.government_bond_emissions) === 0 ? '0' : formatValue(dataTable.government_bond_emissions) || null,
			contractor_id: store.state.userData.contractor, // from store
			month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
			year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
			// type: 0,
			dq_quality_score: dataTable.dq_quality_score || null,
			business_group_id: businessGroupId || null, //todo: update business_group_id by name
			pcaf_business_group_name: dataTable.pcaf_business_group_name || null,
			pcaf_business_name: dataTable.pcaf_business_name || null,
			...layoutsData,
			branch_id: branchId?.id || null,
			evidence: dataTable.evidence || null,
			currency: dataTable.currency || null,
			status: dataTable.status ? 1 : 0,
			is_in_approx: isLimitedAttributionFactor ? 1 : 0,
		};

		if (dataTable.evidence) {
			if (dataTable.is_primary_data) {
				delete dataTable.evidence.value;
			}
			else {
				dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
			}
		}
		if (isSaveTemplate) {
			dataTableItemCustom.evidence = null;
		}

		return dataTableItemCustom;
	},

	addBlankItemsToView: (view, count) => {
		const lastClientId = view.itemCount
		for (let index = 1; index <= count; index++) {
			view.addNew(blankData(lastClientId + index));
		}

		view.commitNew();
	},

	filterColumns: [ //todo: update or remove
		'organizational_division',
		'company_name',
		'business_name',
		'business_group_id',
		'scope',
		'currency',
		'dq_quality_score',
	],

	getError(item, propName, dbStore, validateErrorBtn = {}) {
    const {itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = []} = validateErrorBtn;

		if (notNullList.includes(propName)) {
			if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
				return MESSAGE_NOT_NULL;
			}
		}

		if (numberField.includes(propName)) {
			if (!item[propName] && item[propName] !== 0) {
				return MESSAGE_NOT_NULL
			} else {
				return validateNumberField(String(item[propName]), propName);
			}
		}

		if (max25Charactor.includes(propName)) {
			if (numberField.includes(propName)) {
				return validateNumberField(String(item[propName]), propName);
			}
			return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
		}

		if (max128Charactor.includes(propName)) {
			return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
		}

		if(isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if(!branchId) {
        return `入力された組織区分/法人名/${itemsInViewError.map(item => item)}の組み合わせが存在しません。`;
      }
    }
		return null
	},

	cellInputInView(bindingLayout) {
		return [
			'organizational_division',
			'company_name',
			'pcaf_business_group_name',
			'pcaf_business_name',
			'scope',
			'memo_1',
			'memo_2',
			'loan_amount',
			'category_1',
			'category_2',
			'gdp_ppp_adjusted',
			'ides_industry',
			'attach_file',
			'government_bond_emissions',
			'classify_gics',
			'currency',
			'dq_quality_score',
			...bindingLayout
		];
	},

	cellRequireInView(bindingLayout) {
		return [
			'organizational_division',
			'company_name',
			// 'pcaf_business_group_name',
			// 'pcaf_business_name',
			'scope',
			'loan_amount',
			'gdp_ppp_adjusted',
			'ides_industry',
			'attach_file',
			'memo_1',
			'memo_2',
			'government_bond_emissions',
			'classify_gics',
			'currency',
			...bindingLayout
		];
	},
	patternUrl: '/pattern-s3-c15/11',
	// valueSource: 'ides_industry',

	autoFields: [],

	calcEmissions(rowData) {
		return calcEmissions(rowData)
	},
	getQualityScore(typeData, items) {
		return getScoreByType(typeData, items)[0];
	  },
	bindingValueColumn: 'loan_amount',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = {...filterIndex, ...filterIndexValue }
  }
};

export default parternS3c15pcaf7;
