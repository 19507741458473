import * as wjGrid from "@mescius/wijmo.grid";
import * as wjcCore from "@mescius/wijmo";
import store from "@/store";
import { AutoComplete } from "@mescius/wijmo.input";
import { KEYS_CODE } from "@/constants/keyboard";
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  ORGANIZATIONAL_DIVISION
} from "@/constants/registerData";
import {
  validateMaximumCharactorOnlyFacility,
  validateDate
} from "@/utils/validate";
import debounce from "lodash/debounce";
import { formatValue, $_helper_isNumberType, validateNumberField, math, formatBigNumber, formatEmission25Number } from './wijmo.helper'
import {
  handlerFilterData,
  handlerAddFilterData,
  addFilterByPasted,
  getColumnIndexByBinding,
  getBranchId,
  checkFilterData
} from "@/concerns/utils/filter-data";
import i18n from '@/lang/i18n.js';
import { getMinWidthDirDateSource } from '@/utils/multiLanguage';
import { getDbCustomizeOrDbMasterById } from '@/concerns/utils/master-or-customize-db'
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { getWidthOfAttachedButton, getWidthOfSearchButton } from '@/utils/multiLanguage';
export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    business_name: null,
    emissions: null,
    kinds: null,
    identification_number: null,
    dir_date: null,
    dir_remark: null,
    dir_value: null,
    dir_unit: null,
    ait_remark: null,
    duration_id: null,
    month: null,
    year: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    invalid_dir_date_source: null,
    dir_source: null,
    purchase_level_unit: null
  };
};
const calcEmissions = (emissionsFactor, purchaseLevel ) => {
  if($_helper_isNumberType(emissionsFactor) && $_helper_isNumberType(purchaseLevel)){
    const emissionsFactorVal = math.bignumber(formatValue(emissionsFactor));
    const val_wsuValueVal = math.bignumber(formatValue(purchaseLevel));
    return  formatBigNumber(math.evaluate(`${emissionsFactorVal.toString()} * ${val_wsuValueVal.toString()}`), 50)
  }

  return 0;
};

const notNullInView = [
  "dir_unit"
];

const notNullList = [
  "organizational_division",
  "company_name",
  "kinds",
  "identification_number",
  "dir_date",
  "dir_value",
  "dir_unit",
  'certification_standard_name_id',
  'certification_standard_type_id',
  'purchase_level_unit',
  'purchase_level',
];

const max25Charactor = [
  "dir_value",
  "dir_unit"
];

const max128Charactor = [
  // "company_name",
  // "business_name",
  "kinds",
  "identification_number",
  "dir_date",
  "dir_remark",
  "ait_remark",
  "memo_1",
  "memo_2",
  "invalid_dir_date_source",
  "dir_source"
];

const numberField = [
  "dir_value"
];

const dateField = [
  "dir_date"
];

let isReverted = false

const filterColumns = ["organizational_division", "company_name", "business_name", "country", "layer_3", "layer_4", "layer_5", "layer_6"];
let listMaxWidths = []
const autoBindingDbSource = dataBinding => {
  const defaultCo2 = 't-CO2'
  const defaultEmissionFactor = '0.000396'
  const defaultEmissionFactorByCo2 = '1'
  let { s, binding, row, cellData, currentItem, selectedFirst, e } = dataBinding
  const certificationlist = Object.values(store.getters['registerData/getCertificationTypeCustome'])
  if (binding === 'emissions_factor') {
    s.deferUpdate(() => {
      s.setCellData(row, getColumnIndexByBinding(s,'emissions'), calcEmissions(cellData, currentItem.purchase_level), false, true)
      s.setCellData(row, getColumnIndexByBinding(s,'dir_value'), calcEmissions(cellData, currentItem.purchase_level), false, true)
    })
  }

  if (binding === 'purchase_level') {
    s.deferUpdate(() => {
      const emissions = calcEmissions(cellData, currentItem.emissions_factor)
      s.setCellData(row, getColumnIndexByBinding(s,'emissions'), emissions, false, true)
      s.setCellData(row, getColumnIndexByBinding(s,'dir_value'), emissions, false, true)
    })
  }

  if (binding === 'purchase_level_unit') {
    let emissionFactorVal = (cellData === defaultCo2 || !cellData) ? defaultEmissionFactorByCo2 : defaultEmissionFactor 
    s.deferUpdate(() => {
      s.setCellData(row, getColumnIndexByBinding(s,'emissions_factor'), emissionFactorVal, false, true)
      const emissions = calcEmissions(currentItem.purchase_level, currentItem.emissions_factor)
      s.setCellData(row, getColumnIndexByBinding(s,'emissions'), emissions, false, true)
      s.setCellData(row, getColumnIndexByBinding(s,'dir_value'), emissions, false, true)
      let emissionFactorUnit = currentItem.purchase_level_unit === defaultCo2 ? defaultCo2 : `${currentItem.purchase_level_unit}/${defaultCo2}`
      if(!currentItem.purchase_level_unit) {
        emissionFactorUnit = null
      }
      s.setCellData(row, getColumnIndexByBinding(s, "emission_factor_unit"), emissionFactorUnit, false, true);
      s.setCellData(row, getColumnIndexByBinding(s, "dir_unit"), "t-CO2", false, true);
    })
    if(!cellData) {
      s.deferUpdate(() => {
        s.setCellData(row, getColumnIndexByBinding(s,'emissions_factor'), null, false, true)
        s.setCellData(row, getColumnIndexByBinding(s,'emissions'), null, false, true)
        s.setCellData(row, getColumnIndexByBinding(s,'dir_value'), null, false, true)
        s.setCellData(row, getColumnIndexByBinding(s, "dir_unit"), null, false, true);
      })
    }
  }

  if(binding === 'certification_standard_type_id') {
    const typeSelectDefault = store.getters['registerData/getCertificationType']
    const certificationTypeSelect = typeSelectDefault.find(item => item.name === cellData)
    if(certificationTypeSelect) {
      const nameSelected = currentItem.certification_standard_name_id
      const nameListByType = store.getters['registerData/getCertificationName']?.filter(item => certificationTypeSelect.parents?.includes(item.id))
      if(nameListByType?.findIndex(item => item.name === nameSelected) < 0) {
        s.setCellData(row, getColumnIndexByBinding(s, "certification_standard_name_id"), null, false, true);
      }
    }
    if(!currentItem.purchase_level_unit) {
      s.setCellData(row, getColumnIndexByBinding(s, "purchase_level_unit"), defaultCo2, false, true);
      s.setCellData(row, getColumnIndexByBinding(s, "emissions_factor"), defaultEmissionFactorByCo2, false, true);
      s.setCellData(row, getColumnIndexByBinding(s, "emission_factor_unit"), defaultCo2, false, true);
      s.setCellData(row, getColumnIndexByBinding(s, "dir_unit"), "t-CO2", false, true);
    }

    const typeSelect = currentItem.certification_standard_type_id
    const nameSelect = currentItem.certification_standard_name_id
    if(typeSelect && nameSelect) {
      const certificationCustome = store.getters['registerData/getCertificationTypeCustome']
      const cetifiSelected = certificationCustome[nameSelect + typeSelect]
      if(cetifiSelected) {
        const unitList = Array.from(cetifiSelected.unit).map(item => {
          return { name: item }
        })
        const indexSource = s.columns.findIndex(item => item.binding === 'purchase_level_unit')
        s.columns[indexSource].editor.itemsSource = unitList
        if(cetifiSelected.unit?.findIndex(item => item === currentItem.purchase_level_unit) < 0) {
          const newUnit = cetifiSelected.unit[0]
          s.setCellData(row, getColumnIndexByBinding(s, "purchase_level_unit"), newUnit, false, true);
          s.setCellData(row, getColumnIndexByBinding(s, "emissions_factor"), newUnit === defaultCo2 ? defaultEmissionFactorByCo2 : defaultEmissionFactor, false, true);
          const emissions = calcEmissions(currentItem.purchase_level, currentItem.emissions_factor)
          s.setCellData(row, getColumnIndexByBinding(s,'emissions'), emissions, false, true)
          s.setCellData(row, getColumnIndexByBinding(s,'dir_value'), emissions, false, true)
          const emissionFactorUnit = newUnit=== defaultCo2 ? defaultCo2 : `${newUnit}/${defaultCo2}`
          s.setCellData(row, getColumnIndexByBinding(s, "emission_factor_unit"), emissionFactorUnit, false, true);
        }
      }
    }
  }

  if(binding === 'certification_standard_name_id') {
    const typeSelect = currentItem.certification_standard_type_id
    const typeSelectByFilter = certificationlist.filter(item => item.fullname?.includes(cellData))
    if(typeSelectByFilter?.findIndex(item => item.name === typeSelect) < 0) {
      s.setCellData(row, getColumnIndexByBinding(s, "certification_standard_type_id"), null, false, true);
    }
    if(!currentItem.purchase_level_unit) {
      s.setCellData(row, getColumnIndexByBinding(s, "purchase_level_unit"), defaultCo2, false, true);
      s.setCellData(row, getColumnIndexByBinding(s, "emissions_factor"), defaultEmissionFactorByCo2, false, true);
      s.setCellData(row, getColumnIndexByBinding(s, "emission_factor_unit"), defaultCo2, false, true);
      s.setCellData(row, getColumnIndexByBinding(s, "dir_unit"), "t-CO2", false, true);
    }

    const nameSelect = currentItem.certification_standard_name_id
    if(typeSelect && nameSelect) {
      const certificationCustome = store.getters['registerData/getCertificationTypeCustome']
      const cetifiSelected = certificationCustome[nameSelect + typeSelect]
      if(cetifiSelected) {
        const unitList = Array.from(cetifiSelected.unit).map(item => {
          return { name: item }
        })
        const indexSource = s.columns.findIndex(item => item.binding === 'purchase_level_unit')
        s.columns[indexSource].editor.itemsSource = unitList

        if(cetifiSelected.unit?.findIndex(item => item === currentItem.purchase_level_unit) < 0) {
          const newUnit = cetifiSelected.unit[0]
          s.setCellData(row, getColumnIndexByBinding(s, "purchase_level_unit"), newUnit, false, true);
          s.setCellData(row, getColumnIndexByBinding(s, "emissions_factor"), newUnit === defaultCo2 ? defaultEmissionFactorByCo2 : defaultEmissionFactor, false, true);
          const emissions = calcEmissions(currentItem.purchase_level, currentItem.emissions_factor)
          s.setCellData(row, getColumnIndexByBinding(s,'emissions'), emissions, false, true)
          s.setCellData(row, getColumnIndexByBinding(s,'dir_value'), emissions, false, true)
          const emissionFactorUnit = newUnit=== defaultCo2 ? defaultCo2 : `${newUnit}/${defaultCo2}`
          s.setCellData(row, getColumnIndexByBinding(s, "emission_factor_unit"), emissionFactorUnit, false, true);
        }
      }
    }
  }
}

let filterIndex = {};
const partern27 = {
  initializeGrid(flexgrid, itemCount, branchData = {}, dbStore) {
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    let selectedFirstOnFilter = null;
    const dataStore = getDbCustomizeOrDbMasterById(dbStore.dbCustomizes)
    const defaultCo2 = 't-CO2'
    const defaultEmissionFactor = '0.000396'
    const defaultEmissionFactorByCo2 = '1'
    const unitListDefault = [
      { name: 't-CO2' },
      { name: 'kWh' },
      { name: 'MJ' },
    ]

    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return;
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount;

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100));

    document.addEventListener("keydown", (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener("keydown", (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
          flexgrid.selection = cellRange;

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange;
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange = new wjGrid.CellRange(0, currentSelection.col);
          flexgrid.selection = cellRange;
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
          flexgrid.selection = cellRange;
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1);
          flexgrid.selection = cellRange;
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount;

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }

    }, false);

    flexgrid.pasted.addHandler((s, e) => {
      const {
        col,
        col2,
        row,
        row2
      } = e.range;
      const source = s.rows;
      if (row2 === row) {
        s.setCellData(row, getColumnIndexByBinding(s, "dir_unit"), "t-CO2", false, true);
      }
      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s, e, colIndex, rowIndex, filterIndex);
              if (typeof checkFilterDataAfterPasted[rowIndex] === "undefined") {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          if (['dir_value', 'emissions_factor', 'purchase_level', 'purchase_level_unit', 'certification_standard_type_id', 'certification_standard_name_id'].includes(s.columns[colIndex].binding)) {
            const cellData = s.getCellData(rowIndex, colIndex, false)
            const currentItem = source[rowIndex]?.dataItem || {}
            const binding = s.columns[colIndex].binding
            if (!s.rows[rowIndex]) continue;
            const rowIndex1 = s.rows.findIndex(row => row.dataItem?.clientRowId === currentItem?.clientRowId) || 0;
            const dataBinding = { s, binding, row: rowIndex1, cellData, currentItem, selectedFirst, e }
            autoBindingDbSource(dataBinding)
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(branchData.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted);
        store.dispatch("registerData/updateListErrorFilterDataMessage", errorMessage);
        checkFilterDataAfterPasted = {};
      }
    });

    flexgrid.cellEditEnded.addHandler((s, e) => {
      
      let column = s.columns[e.col];
      const { row, col } = e.range;
      const cellData = s.getCellData(row, col, false);
      const view = s.collectionView;
      const source = view.items;
      const currentItem = source[row] || {};
      const certificationlist = Object.values(store.getters['registerData/getCertificationTypeCustome'])
      const binding = column.binding
      const item = s.rows[e.row].dataItem;
      const dataBinding = { s, binding, row, cellData, currentItem, selectedFirst, e }
      autoBindingDbSource(dataBinding)
      if(column.binding === 'certification_standard_type_id') {
        if(!selectedFirst && cellData && e.data !== cellData)  {
          selectedFirst = column.binding
        }
      }
      if(column.binding === 'certification_standard_name_id') {
        if(!selectedFirst && cellData && e.data !== cellData)  {
          selectedFirst = column.binding
        }
      }


      if (filterColumns.includes(column.binding)) {
        handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirstOnFilter, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];
      let item = s.rows[e.row].dataItem;

      if (['emissions', 'dir_unit', 'dir_value', 'emission_factor_unit'].includes(column.binding)) {// dir_value
        e.cancel = true;
      }

      const { row, col } = e.range;
      const cellData = s.getCellData(row, col, false);
      const view = s.collectionView;
      const source = view.items;
      const currentItem = source[row] || {};
      const certificationlist = Object.values(store.getters['registerData/getCertificationTypeCustome'])

      if (column.binding === "emissions") {
        e.cancel = true;
      }

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(s, e, filterIndex, selectedFirstOnFilter, store.state.registerData, branchData);
      }

      if(currentItem.emissions_factor && currentItem.purchase_level_unit === defaultCo2 && column.binding === "emissions_factor") {
        e.cancel = true;
      }

      if(column.binding === 'certification_standard_name_id') {
        const typeSelect = currentItem.certification_standard_type_id
        const indexSource = s.columns.findIndex(item => item.binding === 'certification_standard_name_id')
        const nameSelectDefault = store.getters['registerData/getCertificationName']
        const filterParentIds = certificationlist.filter(item => item.name === typeSelect)?.map(item => item.parent_id)
        const nameSelectByFilter = nameSelectDefault.filter(item => filterParentIds?.includes(item.id))
        if(typeSelect && selectedFirst && selectedFirst !== 'certification_standard_name_id') {
          s.columns[indexSource].editor.itemsSource = nameSelectByFilter
        } else {
          s.columns[indexSource].editor.itemsSource = nameSelectDefault
        }
      }

      if(column.binding === 'certification_standard_type_id') {
        const nameSelect = currentItem.certification_standard_name_id
        const indexSource = s.columns.findIndex(item => item.binding === 'certification_standard_type_id')
        const typeSelectDefault = store.getters['registerData/getCertificationType']
        const idNameSelected = store.getters['registerData/getCertificationName']?.find(item => item.name === nameSelect)?.id
        const typeSelectByFilter = certificationlist.filter(item => item.fullname?.includes(nameSelect) && item.parent_id === idNameSelected)
        if(nameSelect && selectedFirst && selectedFirst !== 'certification_standard_type_id') {
          s.columns[indexSource].editor.itemsSource = typeSelectByFilter
        } else {
          s.columns[indexSource].editor.itemsSource = typeSelectDefault
        }
      }

      if(column.binding === 'purchase_level_unit') {
        const typeSelect = currentItem.certification_standard_type_id
        const nameSelect = currentItem.certification_standard_name_id
        if(typeSelect && nameSelect) {
          const certificationCustome = store.getters['registerData/getCertificationTypeCustome']
          const cetifiSelected = certificationCustome[nameSelect + typeSelect]
          const unitList = Array.from(cetifiSelected.unit).map(item => {
            return { name: item }
          })
          s.columns[col].editor.itemsSource = unitList
        } else {
          s.columns[col].editor.itemsSource = unitListDefault
        }
      }

      if(!currentItem.certification_standard_name_id && !currentItem.certification_standard_type_id) {
        selectedFirst = null //reset first select
      }
    });
    flexgrid.formatItem.addHandler((s, e) => {
      const colBinding = e.panel.columns[e.col].binding;
      if (e.panel == s.cells && colBinding === 'emissions_factor') {
        const isDisableRow = s.rows[e.row]._data?.purchase_level_unit === defaultCo2;

        if(isDisableRow) {
          // wjcCore.addClass(e.cell, "is-admin-read-only"); //auto-increment
          wjcCore.addClass(e.cell, "auto-increment");
        }
      }
  });
  },

  header(registerData, listLayout, branchData, externalSourcePopup, attachFilePopup, serviceLinkPopup, dbStore, grid, isRemoveStatusColumn) {
    let companyMap = new wjGrid.DataMap(branchData.company_name, "value", "value");
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division
      );
      return validCompany.map((item) => {
        return {
          value: item.value
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem
      );
      return validCompany.map((item) => {
        return {
          value: item.value
        };
      });
    };

    let businessMap = new wjGrid.DataMap(branchData.business_name, "value", "value");
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = branchData?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item["name"], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = branchData?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item["name"], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value
        };
      });
    };

    const certificationName = store.getters['registerData/getCertificationName']
    const certificationType = store.getters['registerData/getCertificationType']
    const unitList = [
      { name: 't-CO2' },
      { name: 'kWh' },
      { name: 'MJ' },
    ]
    const certificationMap = new wjGrid.DataMap(certificationName, "name", "name");
    const certificationTypeMap = new wjGrid.DataMap(certificationType, "name", "name");
    const unitMap = new wjGrid.DataMap(unitList, "name", "name");


    return [
      {
        header: "連番",
        binding: "id", // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t('56_pattern.table_organization_information'),
        align: "center",
        collapseTo: 'organizational_division',
        columns: [
          {
            header: i18n.t('56_pattern.table_organizational_division'),
            binding: "organizational_division", // phan loai to chuc
            minWidth: 115,
            maxWidth: 400,
            allowResizing: true,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            cssClassAll: 'required-field',
            dataMap: new wjGrid.DataMap(registerData.listType, "key", "value"),
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: registerData.listType,
              selectedValuePath: "key",
              displayMemberPath: "value",
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1
            })
          },
          {
            header: i18n.t('56_pattern.table_company_name'),
            binding: "company_name",
            minWidth: 85,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: 'required-field',
            dataMap: companyMap,
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: branchData?.company_name,
              selectedValuePath: "value",
              displayMemberPath: "value",
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1
            })
          },
          ...listLayout,
        ]
      },
      {
        header: i18n.t('56_pattern.table_kinds'), // phan loai
        binding: "kinds",
        minWidth: 70,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        cssClassAll: 'required-field',
      },
      {
        header: i18n.t('56_pattern.table_identification_number'), // so id
        binding: "identification_number",
        minWidth: 109,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        cssClassAll: 'required-field',
      },
      {
        header: i18n.t('56_pattern.table_certification_standard_name_id'),
        binding: "certification_standard_name_id", //TODO: update
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'required-field',
        dataMap: certificationMap,
        editor: new AutoComplete(document.createElement("div"), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: certificationName,
          selectedValuePath: "name",
          displayMemberPath: "name",
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1
        })
      },
      {
        header: i18n.t('56_pattern.table_certification_standard_type_id'),
        binding: "certification_standard_type_id", //TODO: update
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'required-field',
        dataMap: certificationTypeMap,
        editor: new AutoComplete(document.createElement("div"), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: certificationType,
          selectedValuePath: "name",
          displayMemberPath: "name",
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1
        })
      },
      {
        header: i18n.t('56_pattern.table_remarks'),
        align: "center",
        collapseTo: 'memo_1',
        columns: [
          {
            header: i18n.t('56_pattern.table_memo_1'),
            binding: "memo_1",
            minWidth: 90,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
          {
            header: i18n.t('56_pattern.table_memo_2'),
            binding: "memo_2",
            minWidth: 90,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true
          },
        ]
      },
      {
        header: i18n.t('56_pattern.table_purchase_volume_data'), //Purchase
        align: "center",
        columns: [
          {
            header: i18n.t('56_pattern.table_purchase_level'),
            binding: "purchase_level",
            minWidth: 90,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_purchase_level_unit'),
            binding: "purchase_level_unit",
            minWidth: 90,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
            cssClassAll: 'required-field',
            dataMap: unitMap,
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: unitList,
              selectedValuePath: "name",
              displayMemberPath: "name",
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1
            })
          },
        ]
      },
      {
        header: i18n.t('56_pattern.table_emission_factor_data'), //Emission factor
        align: "center",
        columns: [
          {
            header: i18n.t('56_pattern.table_emissions_factor'),
            binding: "emissions_factor",
            minWidth: 90,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
            align: 'right',
          },
          {
            header: i18n.t('56_pattern.table_emission_factor_unit'),
            binding: "emission_factor_unit",
            minWidth: 90,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
            cssClass: "auto-increment"
          },
        ]
      },
      {
        header: i18n.t('56_pattern.table_amortization_or_offset_amount'), //tien khau hao
        align: "center",
        columns: [
          {
            header: i18n.t('56_pattern.table_dir_value'), // Date of invalidation or relocation (numerical value)
            binding: "dir_value",
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClass: "auto-increment",
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_dir_unit'), // Date of invalidation or relocation (unit )
            binding: "dir_unit",
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: "auto-increment",
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_dir_source'), // Amount of invalidation or transfer (remarks)
            binding: "dir_source",
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          }
        ]
      },
      {
        header: i18n.t('56_pattern.table_amortization_or_offset_date'),
        align: "center",
        columns: [
          {
            header: i18n.t('56_pattern.table_dir_date'), // Date of invalidation or relocation (date)
            binding: "dir_date",
            minWidth: 95,
            maxWidth: 150,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: "date-column required-field",
          },
          {
            header: i18n.t('56_pattern.table_invalid_dir_date_source'), // Date of invalidation or relocation (remark)
            binding: "invalid_dir_date_source",
            minWidth: getMinWidthDirDateSource(),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          }
        ]
      },
      {
        header: i18n.t('56_pattern.table_emissions'),
        binding: "emissions",
        cssClass: "auto-increment",
        cssClassAll: "emission",
        align: "right",
        minWidth: 90,
        maxWidth: 400,
        allowSorting: false,
        isRequired: false
      },
      {
        header      : i18n.t('56_pattern.table_attach_file'),
        binding     : "attach_file",
        cssClass: 'auto-increment',
        minWidth    : getWidthOfAttachedButton(i18n.locale),
        maxWidth    : getWidthOfAttachedButton(i18n.locale),
        allowSorting: false,
        isRequired  : false,
        cssClassAll: "btn-db attach-file hide-filter",
        cellTemplate: (ctx, el) => {
          let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
          const button = CellMaker.makeButton({
            text: text,
            click: (e, context) => attachFilePopup(e, context)
          })
          return button(ctx, el);
        } 
      },
      {
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired  : false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
    ];
  },

  handlerDataOnTable(dataTable, bindingLayout, isSaveTemplate = false, branchData, isGetBranchDetail = true, dbStore, isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber, typeOfPattern, isDeleteTingEvent, variables = {}) {
    const {setAutoField = true} = variables;
    const defaultCo2 = 't-CO2'
    const defaultEmissionFactor = '0.000396'
    const defaultEmissionFactorByCo2 = '1'
    dataTable.wsu_value = 't-CO2';
    const emissions = calcEmissions(dataTable.emissions_factor, dataTable.purchase_level);
    const typeSelect = dataTable.certification_standard_type_id
    const nameSelect = dataTable.certification_standard_name_id
    if(typeSelect && nameSelect) {
      const certificationCustome = store.getters['registerData/getCertificationTypeCustome']
      const cetifiSelected = certificationCustome[nameSelect + typeSelect]
      if(cetifiSelected && setAutoField) {
        if(cetifiSelected.unit?.findIndex(item => item === dataTable.purchase_level_unit) < 0) {
          const newUnit = cetifiSelected.unit[0]

          dataTable.purchase_level_unit = newUnit
          dataTable.emissions_factor = newUnit === defaultCo2 ? defaultEmissionFactorByCo2 : defaultEmissionFactor
          const emissions = calcEmissions(dataTable.purchase_level, dataTable.emissions_factor)
          dataTable.emissions = emissions
          dataTable.dir_value = emissions
          const emissionFactorUnit = newUnit=== defaultCo2 ? defaultCo2 : `${newUnit}/${defaultCo2}`
          dataTable.emission_factor_unit = emissionFactorUnit
          dataTable.dir_unit = defaultCo2
        }
      }
    }
    if(!isGetBranchDetail) return
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};


    const isSelectFullType = typeSelect && nameSelect
    const certificationCustome = store.getters['registerData/getCertificationTypeCustome']
    const certificationTypeObj = store.getters['registerData/getCertificationTypeObj']
    const certificationNameObj = store.getters['registerData/getCertificationNameObj']
    const isTypeSelected = certificationTypeObj[typeSelect]?.id || null
    const isNameSelected = certificationNameObj[nameSelect]?.id || null
    const cetifiSelected = certificationCustome[nameSelect + typeSelect]

    if (typeof dataTable.evidence === 'string') { 
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      emissions: formatEmission25Number(emissions),
      dir_value: formatEmission25Number(emissions),
      kinds: dataTable.kinds || null,
      identification_number: dataTable.identification_number || null,
      dir_date: dataTable.dir_date || null,
      dir_remark: dataTable.dir_remark || null,
      dir_unit: dataTable.dir_unit || null,
      ait_remark: dataTable.ait_remark || null,
      invalid_dir_date_source: dataTable.invalid_dir_date_source || null,
      dir_source: dataTable.dir_source || null,
      contractor_id: store.state.userData.contractor, // from store
      month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
      year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
      type: null,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      certification_standard_name_id: isSelectFullType ? cetifiSelected?.parent_id : isNameSelected || null,
      certification_standard_type_id: isSelectFullType ? cetifiSelected?.id : isTypeSelected || null,
      purchase_level_unit: dataTable.purchase_level_unit || null,
      emission_factor_unit: dataTable.emission_factor_unit || null,
      purchase_level: isSaveTemplate ? '' : formatValue(dataTable.purchase_level) === 0 ? '0' : formatValue(dataTable.purchase_level) || null,
      emissions_factor: isSaveTemplate ? '' : dataTable.emissions_factor || null,
      evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
    };
      
    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }
    
    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount;
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    "organizational_division",
    "company_name",
    "business_name",
    "kinds",
    "identification_number",
    "dir_remark",
    "dir_unit",
    "ait_remark"
  ],
  getError(item, propName, dbStore, validateErrorBtn = {}) {
    const {itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = []} = validateErrorBtn;

    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === "" || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      } else {
        return validateNumberField(String(item[propName]));
      }
    }

    if (max25Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 25);
    }

    if (max128Charactor.includes(propName)) {
      if (dateField.includes(propName)) {
        return validateDate(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if(isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if(!branchId) {
        return `入力された組織区分/法人名/${itemsInViewError.map(item => item)}の組み合わせが存在しません。`;
      }
    }
    return null;
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'activity',
      'memo_1',
      'memo_2',
      'aoa_value',
      'aoa_unit',
      'aoa_source',
      'wsu_value',
      'wsu_unit',
      'wsu_source',
      'dir_source',
      'dir_value',
      'invalid_dir_date_source',
      'dir_date',
      'identification_number',
      'kinds',
      'certification_standard_name_id',
      'certification_standard_type_id',
      'purchase_level_unit',
      'purchase_level',
      'attach_file',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'activity',
      'memo_1',
      'memo_2',
      'aoa_value',
      'aoa_unit',
      'aoa_source',
      'wsu_value',
      'wsu_unit',
      'wsu_source',
      'certification_standard_name_id',
      'certification_standard_type_id',
      'purchase_level_unit',
      'purchase_level',
      ...bindingLayout
    ];
  },

  autoFields: ['certification_standard_name_id'],
  patternUrl: '/pattern-s4-c1/',

  calcEmissions(dataTable) {
    return calcEmissions(dataTable.emissions_factor, dataTable.purchase_level);
  },

  getMaxWidthByContent(listDataView, columns, getWidthbyContent, theGrid) {
    let resultArrayWidth = [];

    columns.forEach((column) => {
      const paddingDatamap = column?.dataMap ? 10 : 0;
      const certificationName = store.getters['registerData/getCertificationName']
      const certificationType = store.getters['registerData/getCertificationType']

      let indexMaxLengthOfColumn = listDataView?.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = listDataView[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = listDataView[indexMaxLengthOfColumn];

        // check length
        const maxlength128 = 128;
        const maxlength25 = 25;
        if (max128Charactor.includes(column.binding) && dataLength > maxlength128) {
          const newData = rowData?.[column.binding]?.toString()?.slice(0, maxlength128)
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength128
        }

        if (max25Charactor.includes(column.binding) && formatValue(rowData?.[column.binding])?.toString()?.length > maxlength25) {
          const emission25Number = formatValue(rowData?.[column.binding])?.includes('-') ? 26 : 25;
          const newData = addThoundSandComma(formatValue(rowData?.[column.binding])?.substring(0, emission25Number)) || '0';
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength25
        }
        //end check length

        if (column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
          const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }

        // if (column.binding === 'certification_standard_name_id' && rowData?.certification_standard_name_id) {
        //   const lengthItem = certificationName.find(item => item.id === rowData.certification_standard_name_id).name?.length
        //   const lengthItemMaxLength = certificationName.find(item => item.id === curentDataInView.certification_standard_name_id).name?.length
        //   dataLength = lengthItem
        //   dataLengthInView = lengthItemMaxLength
        // }

        // if (column.binding === 'certification_standard_type_id' && rowData?.certification_standard_type_id) {
        //   const lengthItem = certificationType.find(item => item.id === rowData.certification_standard_type_id).name?.length
        //   const lengthItemMaxLength = certificationType.find(item => item.id === curentDataInView.certification_standard_type_id)?.name?.length
        //   dataLength = lengthItem
        //   dataLengthInView = lengthItemMaxLength
        // }

        if (dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = listDataView[indexMaxLengthOfColumn];
      let contentMaxWidth = dataMaxLengthInview?.[column.binding]
      if (column.binding === 'organizational_division') {
        contentMaxWidth = ORGANIZATIONAL_DIVISION[dataMaxLengthInview.organizational_division - 1]
      }

      // if (column.binding === 'certification_standard_name_id') {
      //   contentMaxWidth = certificationName.find(item => item.id === dataMaxLengthInview.certification_standard_name_id)?.name
      // }

      // if (column.binding === 'certification_standard_type_id') {
      //   contentMaxWidth = certificationType.find(item => item.id === dataMaxLengthInview.certification_standard_type_id)?.name
      // }

      let widthOfCellMaxlengh = getWidthbyContent(contentMaxWidth) + paddingDatamap
      const widthHeaderOfCellMaxLength = getWidthbyContent(column.header, true)
      if(column.binding === 'certification_standard_name_id') {
        widthOfCellMaxlengh = widthOfCellMaxlengh += 15
      }
      resultArrayWidth.push(widthOfCellMaxlengh > widthHeaderOfCellMaxLength ? widthOfCellMaxlengh : widthHeaderOfCellMaxLength)
    })
    listMaxWidths = resultArrayWidth
    return resultArrayWidth
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if (width < column.minWidth) {
        width = column.minWidth
        if (column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const { row, col } = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if (!cellData) return

    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);

    if (listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const { row, row2 } = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = listDataView[indexMaxLengthOfColumn];

        if (column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
          const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if (dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if (listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },
  bindingValueColumn: 'purchase_level',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = {...filterIndex, ...filterIndexValue }
  }
};

export default partern27;
