import request from './base/request';
const GROUP_ENTERPRISE_SERVICE = '/business-group';

export const getListGroupEnterpriseApi = () => {
  return request
    .get(GROUP_ENTERPRISE_SERVICE)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        throw err;
    });
};

export const createGroupEnterprise = (payload) => {
  return request
    .post(GROUP_ENTERPRISE_SERVICE, payload)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        throw err;
    });
};

export const updateGroupEnterprise = (payload) => {
  return request
    .put(GROUP_ENTERPRISE_SERVICE, payload)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        throw err;
    });
};

export const businessGroupList = () => {
  return request
    .get('/business-group/list')
    .then((res) => {
      let group_obj = {}
      let company_obj = {}
      let company_id_obj = {}
      res.forEach(group_company => {
        // check if dont has obj business_group_name
        if(!group_obj[group_company.business_group_name]) {
          group_obj[group_company.business_group_name] = group_company
        }
        if(group_company?.child?.length > 0) {
          group_company?.child?.forEach(company => {
            company_id_obj[company.id] = company
            if(!company_obj[company.business_name]) { //check key is company name in obj
              company[group_company.business_group_name] = {}
              company[group_company.business_group_name]['id'] = company.id // set business_group_name in sub key
              company_obj[company.business_name] = company
            } else {
              // set business_group_name in sub key if same company name & !same business_group_name
              company_obj[company.business_name][group_company.business_group_name] = {}
              company_obj[company.business_name][group_company.business_group_name]['id'] = company.id
            }
          })
        }
      })

      let groups = {
        'item': [],
        'pcaf_business_group_name': [],
        'pcaf_business_name': [],
      };
      res.forEach(item => {
        groups['pcaf_business_group_name'].push(item.business_group_name);
        item?.child.forEach(childItem => {
          groups['item'].push({
            id: childItem.id,
            pcaf_business_group_name: childItem.business_group_name,
            pcaf_business_name: childItem.business_name,
          });
          groups['pcaf_business_name'].push(childItem.business_name);
        })
      })
      groups['pcaf_business_group_name'] = [...new Set(groups['pcaf_business_group_name'])];
      groups['pcaf_business_name'] = [...new Set(groups['pcaf_business_name'])];

      return {
        groups: groups, 
        dataObj: { 
          group_obj: group_obj, 
          company_obj: company_obj,
          company_id_obj: company_id_obj
        }
      };
    })
    .catch((error) => {
      throw error;
    });
}