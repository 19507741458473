import request from "./base/request";

export const readFile = (data, type) => {
  return request
    .post(`ocr/read-${type}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getListTemplate = (pattern_id, db_master_id) => {
  return request
    .get('/template-ocr/list-template', {
      params: {
        pattern_id,
        db_master_id,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getInfoPlanOcr = () => {
  return request
    .get('ocr/info-plan-ocr')
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFileReadOcr = (data) => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', 'get');

  return request
    .post(`ocr/storage`, body, {
      responseType: "blob"
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
