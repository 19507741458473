<template>
  <div class="container">
    <div v-if="displayStatus" class="status-data-details">
      <span class="text-status-details" :class="colorStatusDataDetails()" >{{statusDataDetails()}}</span>
      <span v-if="displayAsTemplateDetails()" class="text-status-details" :class="colorStatusDataDetails()" >{{ templateType }}</span>
    </div>
    <div class="title-page">
      <div class="title">
        <img class="title-icon" :src="getUrlIcon" />
        <div class="title-description">
          <span> {{ getTitle }}</span> 
          <div class="helper-icon">
          <img
            :src="showIconOnTiTlePattern('helper-icon.svg')"
            @mouseover="changeTooltipImage($event)"
            @mouseleave="changeTooltipImage($event, 'mouseleave')"
            width="100%"
            height="100%"
            alt=""
          />
          <div v-if="showTooltip" :class="{'tooltip-es': category === 3}" class="helper-icon tooltip" v-html="getDetailOnTitlePattern"></div>
        </div>
      </div>      
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { DESCRIPTIONS } from '@/constants/descriptions';
import {ROLE} from '@/constants/role';
import { ROUTES } from '@/router/constants';
import { STATUS_FOR_TEMPLATE, STATUS_FIELD } from '@/constants/status';
import { getStatusNameById, getColorStatusById } from "@/utils/status";
import { SCOPE, CATEGORY } from '@/constants/registerData'
import { mapState } from 'vuex';

export default {
  props: {
    category: {
      type: Number,
      default: 1,
    },
    scope: {
      type: Number,
      default: 1,
    },
    workFlowData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      showTooltip: false,
      templateType: ''
    };
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    displayStatus() {
      if(Object.keys(this.workFlowData).length) {
        return true;
      }
      return false;
    },
    getTitle() {
      if (this.scope > SCOPE.SCOPE_2) {
        if (this.category === CATEGORY.CATEGORY_16) {
          return `${this.$t('dashboard_main.label_scope')} ${this.scope} > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
        } else if (this.scope === SCOPE.SCOPE_4) {
          return `${this.$t('register_data.title_emission_scope4')} > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
        } else {
          return (
            `${this.$t('dashboard_main.label_scope')} ${this.scope} > ${this.category}. ` +
            DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title']
          );
        }
      } else {
        return `${this.$t('dashboard_main.label_scope')} ${this.scope} > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'];
      }
    },
    getDetailOnTitlePattern() {
      return DESCRIPTIONS['scope' + this.scope]['category' + this.category]['description'];
    },
    getUrlIcon() {
      return require(`@/assets/images/registerData/scope${this.scope}category${this.category}.svg`);
    },
    ...mapState('registerData', ['method']),
  },
  mounted() {
    this.displayAsTemplateDetails();
  },
  methods: {
    showIconOnTiTlePattern(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    statusDataDetails() {
      let status = getStatusNameById(this.workFlowData?.status);

      if((this.$route.fullPath.includes('/emissions/register/approval/') || this.$route.fullPath.includes('/emissions/register/primary-data/')) && this.workFlowData?.status === STATUS_FIELD.STATUS_SUBMIT ) {
        status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
      }

      if(this.$route.fullPath.includes('/emissions/register/approval/') && this.workFlowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED ) {
        status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
      }

      if(this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES) {
        status =  getStatusNameById(STATUS_FOR_TEMPLATE);
      }
      return status;
    },
    colorStatusDataDetails() {
      if((this.$route.fullPath.includes('/emissions/register/approval/') || this.$route.fullPath.includes('/emissions/register/primary-data/')) && this.workFlowData?.status === STATUS_FIELD.STATUS_SUBMIT ) {
        return `color-${getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER)}`;
      }
      // if((this.workFlowData?.is_approver && !this.workFlowData?.is_owner && (this.currentUser?.user?.role_id === ROLE.APPROVAL || this.currentUser?.user?.role_id === ROLE.ADMIN )) && this.workFlowData?.status === STATUS_FIELD.STATUS_SUBMIT) {
      //   return `color-${getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER)}`;
      // }
      if(this.$route.fullPath.includes('/emissions/register/approval/') && this.workFlowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED ) {
        return `color-${getColorStatusById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER)}`;
      }
      // if((this.workFlowData?.is_approver && !this.workFlowData?.is_owner && (this.currentUser?.user?.role_id === ROLE.APPROVAL || this.currentUser?.user?.role_id === ROLE.ADMIN )) && this.workFlowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
      //   return `color-${getColorStatusById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER)}`;
      // }

      if(this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES) {
        return `color-${getColorStatusById(STATUS_FOR_TEMPLATE)}`;
      }

      return `color-${getColorStatusById(this.workFlowData?.status)}`;
    },
    displayAsTemplateDetails() {
      if(this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES) {
        if(this.workFlowData.template_type) {
          this.templateType = this.$t('b_list_template.pulldown_currently_shared');
        } else {
          this.templateType = this.$t('b_list_template.pulldown_template_private');
        }
        return true;
      }
      return false;
    },

    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.showIconOnTiTlePattern('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.showIconOnTiTlePattern('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.status-data-details {
  margin: 0px 0px 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
    .text-status-details {
    padding: 2px 8px 3px;

    height: 23px;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    border-radius: 2px;
  }
}
.color-blue {
  color: #0072A3;
  border: 1px solid #0072A3;
}
.color-red {
  color: $redMid;
  border: 1px solid $redMid;
}

.color-light-green {
  color: $goldMid;
  border: 1px solid $goldMid;
}
.color-mono-black {
  color: #404D50;
  border: 1px solid #404D50;
}

.title {
  display: flex;
  flex-direction: column;
  // height: 48px;
  .title-icon {
    max-width: 48px;
    width: 48px;
  }
  .title-description {
    text-align: left;
    font-family: $mainFont;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    margin-top: 8px;
    margin-bottom: 8px;
    color: $goldMid;
  }
  .title-icon--tooltip {
    cursor: pointer;
    margin-left: 0;
    // margin-bottom: 48px;
    max-width: 20px;
  }
}
.method-detail {
  font-family: $mainFont;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.05em;
  margin-bottom: 48px;

  color: $monoBlack;
}

.helper-icon {
  width: 18px;
  height: 18px;
  // position: relative;
  margin-top: -6px;
  display: inline-block;
  img {
    z-index: 1;
    display: block;
    margin: 3px auto;
  }
  &.tooltip {
    font-weight: 400;
    display: block;
    justify-content: left;
    background: $monoWhite;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    padding: 10px;
    height: unset;
    width: calc(100% - 80px);
    max-width: max-content;
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: unset;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
}

@media (max-width: 1023px) { 
  .title-description {
    span {
      margin-right: 8px;
    }
  }
}

@include desktop {
  .title {
    // margin-top: 40px;
    // margin-bottom: 48px;
    flex-direction: row;
    align-items: center;
    // height: 48px;
    .title-icon {
      margin-top: 0;
      margin-right: 16px;
      margin-bottom: auto;
      max-width: unset;
    }
    .title-description {
      margin-top: 0;
      margin-bottom: 0;
    }
    .title-icon--tooltip {
      cursor: pointer;
      margin-left: 16px;
      margin-bottom: 0;
      padding-top: 4px;
    }
  }
  .helper-icon {
    margin-left: 16px;
    position: relative;
    &.tooltip {
      position: absolute;
      width: 400px;
      max-width: unset;
    }
  }
  .title-page {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .status-data-details {
    margin: 0px 0px 28px; 
  }
}
.container {
  &.tooltip-none {
    font-size: 14px;
    padding: 20px 0px;
    .title {
      .title-icon{
        display: none;
      }
      .title-description {
      text-align: left;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 0.05em;
      margin-top: 8px;
      margin-bottom: 10px;
      color: #404D50;
    }
    .helper-icon {
      display: none;
    }
    }
  }
}
</style>
