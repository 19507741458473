import request from './base/request';

export const getExportReport = (data, isCsv = false) => {
  const body = new FormData();
	const payloadBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
	body.append('data', payloadBlob);
	body.append('_method', 'get');
  if (isCsv) {
    return request
    .post(`/export-report/csv`, body, { responseType: 'blob' })
    .then(res => {
      return res;
    }).catch((error) => {
      throw error;
    });
  } else {
    return request
    .post(`/export-report`, body, { responseType: 'blob' })
    .then(res => {
      return res;
    }).catch((error) => {
      throw error;
    });
  }
}

export const getExportTemplateCsv = (patternNum, contractor, additionalParamsPcaf = {}) => {
  const queryParams = new URLSearchParams({
    row_num_pattern: patternNum,
    contractor_id: contractor,
    ...additionalParamsPcaf
  });

  return request
    .get(`/download-template/?${queryParams.toString() }`, { responseType: 'blob' })
  .then(res => {
    return res;
  }).catch((error) => {
    throw error;
  });
}

export const getExportReportSummary = (data) => {
  const body = new FormData();
	const payloadBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
	body.append('data', payloadBlob);
	body.append('_method', 'get');
  return request
  .post(`summary-score/export`, body, { responseType: 'blob' })
  .then(res => {
    return res;
  }).catch((error) => {
    throw error;
  });
}