<template>
  <div class="privacy-container">
    <div v-html="data" v-show="data" class="content"></div>
  </div>
</template>
<script>
import { policy } from '@/api/others';

export default {
  data() {
    return {
      data: '',
    };
  },
  mounted() {
    this.getPrivacy();
  },
  methods: {
    getPrivacy() {
      policy().then((response) => {
        this.data = response;
      });
    },
  },
};
</script>
