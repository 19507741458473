<template>
  <v-dialog
    v-model="getDialog"
    fullscreen
    ref="dialog"
    :persistent="true"
    content-class="modal-fullscreen width-100pc"
    transition="dialog-bottom-transition"
  >
    <div class="content-main">
      <div class="head-category-popup">
        <span class="log-confirm_text mr-auto">{{ $t('ocr_service.label_ocr_reading') }}</span>
        <div class="list-emission-close" @click="getDialog = false">
          <img class="head-image-dialog-popup mr-0 log-confirm--btn close" src="@/assets/images/dialog/close.svg" />
          <img
            class="head-image-dialog-popup mr-0 log-confirm--btn close-active"
            src="@/assets/images/dialog/close_active.svg"
          />
          <div class="list-emission-close__text">{{ $t('b_export_report.button_close') }}</div>
        </div>
      </div>

      <div class="form-main">
        <div class="padding-top-layout padding-right-layout form-evidence">
          <div class="listMenu-page list-menu">
            <div class="listMenus-btn">
              <UploadFileRead
                :typeForm="formActionType"
                :formData="formData"
                :statusDialog="getDialog"
                :isDisable="isLoading"
                :listTemplate="listTemplate"
                :infoPlanOcr="infoPlanOcr"
                :dataRow="itemDetail.item"
                :isReadOnly="itemDetail.isReadOnly"
                :energyType="itemDetail.energy_type"
                @onReadFile="submit"
              ></UploadFileRead>
              <DataReadFile :isReadOnly="itemDetail.isReadOnly" :listAlldataFile="responseFile" :typeForm="formActionType"></DataReadFile>
            </div>

            <div class="list-menu-registration">
              <div class="action-form">
                <common-button
                  class="submit-register-btn"
                  :label="$t('popup.button_save_product')"
                  :disabled="validateSubmitBtn"
                  @action="registerData"
                  :loading="isLoading"
                  type="colored"
                  v-ripple="false"
                />
              </div>
            </div>

            <div class="action-fixed" ref="scrollElement">
              <div class="return__button-responsive fixed-button-bottom">
                <div class="registration-responsive">
                  <common-button
                    class="submit-register-btn"
                    :label="$t('popup.button_save_product')"
                    :disabled="validateSubmitBtn"
                    @action="registerData"
                    :loading="isLoading"
                    type="colored"
                    v-ripple="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ErrorPopup :dialog="dialogNotification" :message="messageNotify" @submit="dialogNotification = false" />
  </v-dialog>
</template>
  <script>
import DataReadFile from '@/views/emissions/ReadOcrService/components/dataReadFile.vue';
import UploadFileRead from '@/views/emissions/ReadOcrService/components/uploadFile.vue';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import QuestionPopup from '@/components/dialogs/question-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { readFile, getListTemplate, getInfoPlanOcr } from '@/api/ocr-service';
import { addThoundSandComma } from '@/utils/convertNumber';
import { formatValue } from '@/concerns/newRegisterData/wijmo.helper';

export default {
  components: { CommonButton, NotificationPopup, QuestionPopup, UploadFileRead, DataReadFile, ErrorPopup },
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },

    itemDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        data: '',
      },
      isUpateForm: false,
      isLoading: false,
      dialogNotification: false,
      formActionType: 'create',
      dialogConfirm: false,
      messageNotify: '',
      isPreviewImage: false,
      fileName: '',
      filePackUp: '',
      isInputUpdate: false,
      isFileUpdate: false,
      responseFile: null,
      listTemplate: [],
      fileReadOcr: {},
      infoPlanOcr: {
        count_used_ocr: null,
        setting_ocr_unit_price: null,
      },
    };
  },

  mounted() {},

  computed: {
    getDialog: {
      get() {
        let htmlElement = document.getElementsByTagName('html')[0];
        htmlElement.style.overflow = this.dialog ? 'hidden' : 'auto';
        this.responseFile = null;
        if (this.dialog && this.itemDetail.item.is_ocr) {
          this.formActionType = 'view';
          this.getDataReadFile();
        } else {
          this.formActionType = 'create';
        }
        if (this.dialog) {
          this.getListTemplate();
          this.getInfoPlanOcr();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },

    isReadFile() {
      return this.formActionType === 'create';
    },

    isFileTemplate() {
      return this.itemDetail.energy_type.ocr_type === 2;
    },

    validateUploadImage() {
      return !this.formData.data || !this.formData.template_id;
    },

    validateDataFile() {
      return this.responseFile && !this.responseFile.every((item) => item.memo_1 && item.memo_2 && item.value);
    },

    validateSubmitBtn() {
      // if (this.itemDetail.isReadOnly) {
      //   return this.itemDetail.isReadOnly;
      // } else {
      //   return this.isReadFile ? this.validateUploadImage : this.validateDataFile;
      // }
      return this.validateDataFile || !this.responseFile
    },
  },

  methods: {
    getTitleModal() {
      return this.$t('ocr_service.label_ocr_reading');
    },

    submit() {
      if (this.isReadFile) {
        this.onReadFile();
      } else {
        this.$emit('addDataReadFile', {
          data_read_file: this.responseFile,
          file: this.fileReadOcr,
          template_id: this.formData.template_id,
        });
        this.getDialog = false;
      }
    },

    registerData() {
      this.$emit('addDataReadFile', {
          data_read_file: this.responseFile,
          file: this.fileReadOcr,
          template_id: this.formData.template_id,
        });
        this.getDialog = false;
    },

    cancel() {
      this.getDialog = false;
    },

    onReadFile() {
      if (this.isLoading) return;

      this.isLoading = true;
      let typeFile = this.isFileTemplate ? 'template' : 'receipt';

      let payload = new FormData();
      payload.append('file', this.formData.data);
      payload.append('pattern_id', this.itemDetail.pattern_id);

      if (this.isFileTemplate) {
        payload.append('template_id', this.formData.template_id);
      }

      readFile(payload, typeFile)
        .then((res) => {
          this.fileReadOcr = res.file_read;
          this.responseFile = res.data_read_file.map((item) => {
            return {
              memo_1: item.product,
              memo_2:  addThoundSandComma(formatValue(item.amount)?.substring(0, 25)),
              value: addThoundSandComma(formatValue(item.quantity)?.substring(0, 25)),
              unit: item.unit,
            };
          });
          // this.formActionType = 'view';
          this.getInfoPlanOcr();
        })
        .catch((err) => {
          this.messageNotify = this.$t('ocr_service.description_error_read_file');
          this.dialogNotification = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getListTemplate() {
      if (this.isFileTemplate) {
        getListTemplate(this.itemDetail.pattern_id, this.itemDetail.energy_type.origin_id).then((res) => {
          this.listTemplate = res.data;
        });
      } else {
        this.listTemplate = [{ template_name: this.$t('ocr_service.title_reading_receipt'), id: 'receipt' }];
      }
    },
    async getInfoPlanOcr() {
      try {
        const response = await getInfoPlanOcr();
        this.infoPlanOcr = response;
      } catch (_) {}
    },
    getDataReadFile() {
      const item = this.itemDetail.item;
      this.responseFile = [
        {
          memo_1: item.memo_1,
          memo_2: item.memo_2,
          value: item.value,
          unit: item.unit,
        },
      ];
      this.formData.template_id = item.template_id ? item.template_id : 'receipt';
    },

    openDialogSucess() {
      this.dialogNotification = true;
      if (this.typeForm === 'create') {
        this.messageNotify = this.$t('evidence_storage.label_save_success');
      } else {
        this.messageNotify = this.$t('evidence_storage.label_update_success');
      }
    },
  },
};
</script>
  
<style lang="scss">
.modal-fullscreen {
  background: $bgExtraLight;
}

.modal-open {
  overflow: hidden;
}
.w-full {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.modal-fullscreen {
  position: relative;
  max-width: 100%;

  &.width-100pc {
    max-width: 100%;
    width: 100%;
  }

  .content-main {
    height: 100%;

    @include tablet {
      display: grid;
      grid-template-rows: auto auto auto 1fr;
      align-items: center;
      padding-bottom: 48px;
    }

    .form-main {
      margin-top: 94px;
    }

    .head-category-popup {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      background: #eff5f5;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 40px;
      border-bottom: solid 1px rgba(121, 134, 134, 0.12);

      .log-confirm_text {
        height: 32px;
        font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: $goldMid;
      }

      .list-emission-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 66px;
        height: 62px;
        background: #f7f7f2;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        cursor: pointer;

        &__text {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.03em;
          color: $monoBlack;
        }

        .close-active {
          display: none;
        }

        .close {
          display: block;
        }

        &:hover {
          background: $goldLight;

          .close-active {
            display: block;
          }

          .close {
            display: none;
          }

          .list-emission-close__text {
            color: $monoWhite;
          }
        }

        &:active {
          background: $monoOffWhite;
          border: 2px solid $blueMid;

          .close-active {
            display: none;
          }

          .close {
            display: block;
          }

          .list-emission-close__text {
            color: $monoBlack;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .head-category-popup {
      padding: 16px 20px !important;
    }

    .form-main {
      margin-top: 134px !important;
    }
  }
}

.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}
.form-evidence {
  display: flex;
  flex-direction: column;
}
.listMenu-page {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
  flex: 1;

  .listMenus-btn {
    display: flex;
    padding: 32px 32px 48px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background: #e3eded;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 16px;

      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;

    .action-form {
      display: flex;
      gap: 8px;
      width: 100%;
    }
  }

  .title-btn-select-scope {
    margin-bottom: 12px;
  }

  .listMenus-btn {
    padding: 20px;
  }
}

.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}

.return__header-responsive {
  width: 100%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;

  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;

    .return-active {
      display: none;
    }
  }

  &:hover {
    .list-emission-return-responsive {
      .return {
        display: none;
      }

      .return-active {
        display: block;
      }
    }
  }

  &:active {
    .list-emission-return-responsive {
      .return {
        display: block;
      }

      .return-active {
        display: none;
      }
    }
  }
}

.registration-responsive {
  width: 100%;
  margin: auto;
  padding: 16px 20px;
  display: grid;
  gap: 16px;

  .common-btn {
    width: 100%;
  }
}

.action-fixed {
  display: block;
  height: 128px;
}

.return__button-responsive {
  &.fixed-button-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    top: auto;
    width: 100%;
    margin-left: 0px;
  }
}

@include desktop {
  .listMenu-page {
    .listMenus-btn {
      display: grid;
      grid-gap: 24px;
      width: 100%;
      max-width: 560px;
      margin: 0 auto;
      padding: 32px 32px 48px 32px;

      .listMenu-btn {
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;

          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .select-year-month {
          flex-flow: row !important;
        }
      }
    }

    .action-fixed {
      display: none;
    }

    .submit-register-btn {
      // background: $goldMid !important;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
        0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
        0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
        0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
        0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      border-radius: 4px;
      transition: 0.1s ease-out;

      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 50px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }

      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;

        .list-emission-return {
          height: 20px;

          .return-active {
            display: none;
          }
        }

        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }

            .return-active {
              display: block;
            }
          }
        }

        &:active {
          .list-emission-return {
            .return {
              display: block;
            }

            .return-active {
              display: none;
            }
          }
        }
      }
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }
      }
    }

    .csv-download {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px;
      width: 100%;
      margin-top: 8px;
    }
  }
}
</style>
  