import request from './base/request';

export const getAutoApprovalSetting = () => {
  return request
    .get('/setting/auto-approve')
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateAutoApprovalSetting = (data) => {
  return request
    .put('/setting/auto-approve', data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

// export { getAutoApprovalSetting, updateAutoApprovalSetting }