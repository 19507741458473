<template>
  <div class="container month-selection align-center justify-center">
    <common-button  class="register-btn" :isDisplayBlockPc="true" :isDisable="curentIndexDuration === durationList.length - 1" @action="nextDuration" label="前年度">
      <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.51563 11L11.5156 16L11.5156 6L4.51563 11Z" stroke="currentColor" stroke-linejoin="round" />
        <path d="M8.48438 11L15.4844 16L15.4844 6L8.48438 11Z" stroke="currentColor" stroke-linejoin="round" />
      </svg>
    </common-button>
    <div class="year-block" v-if="endYear === startYear">
      <div class="year">
        <div class="mb-1">{{ startYear }}</div>
      </div>
      <div class="select-month d-flex">
        <div
          class="month cursor-pointer"
          :class="[
            month === item  && startYear <= curentYear && 'active',
            item > curentMonth && startYear >= curentYear && 'event-none',
          ]"
          @click="updateMonth(item, startYear)"
          v-for="(item, i) in getMonthWhenSameYear"
          :key="i"
        >
          {{ item + '月' }}
        </div>
      </div>
    </div>
    <div class="year-block" v-if="endYear !== startYear">
      <div class="year">{{ startYear }}</div>
      <div class="select-month d-flex">
        <div
          class="month cursor-pointer"
          :class="[month === item && 'active', item > curentMonth && startYear >= curentYear && 'event-none']"
          @click="updateMonth(item, startYear)"
          v-for="(item, i) in getFirstMonthWhenNotSameYear"
          :key="i"
        >
          {{ item + '月' }}
        </div>
      </div>
    </div>
    <div class="year-block second-year" v-if="endYear !== startYear">
      <div class="year">{{ endYear }}</div>
      <div class="select-month d-flex">
        <div
          class="month cursor-pointer"
          :class="[
            month === item && 'active',
            ((item > curentMonth && endYear > curentYear) || endYear > curentYear) && 'event-none',
          ]"
          @click="updateMonth(item, endYear)"
          v-for="(item, i) in getLastMonthWhenNotSameYear"
          :key="i"
        >
          {{ item + '月' }}
        </div>
      </div>
    </div>
    <common-button class="register-btn" :isDisplayBlockPc="true" :isDisable="curentIndexDuration === 0" @action="prevDuration" label="次年度">
      <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4844 11L8.48438 6V16L15.4844 11Z" stroke="currentColor" stroke-linejoin="round" />
        <path d="M11.5156 11L4.51562 6V16L11.5156 11Z" stroke="currentColor" stroke-linejoin="round" />
      </svg>
    </common-button>
  </div>
</template>

<script>
import CommonButton from '@/components/utils/button.vue';

export default {
  components: { CommonButton },
  props: {
    endYear: {
      type: Number,
      default: 0,
    },
    startYear: {
      type: Number,
      default: 0,
    },
    curentIndexDuration: {
      type: Number,
      default: 0,
    },
    durationList: {
      type: Array,
      default: () => [],
    },
    curentMonth: {
      type: Number,
      default: 0,
    },
    curentYear: {
      type: Number,
      default: 0,
    },
    month: {
      type: Number,
      default: 0,
    },
    startMonth: {
      type: Number,
      default: 0,
    },
    endMonth: {
      type: Number,
      default: 0,
    },
    year: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    nextDuration() {
      this.$emit('nextDuration');
    },
    updateMonth(item, year) {
      this.$emit('updateMonth', item, year);
    },
    prevDuration() {
      this.$emit('prevDuration');
    },
  },
  computed: {
    getMonthWhenSameYear() {
      let listMonth = [];
      for (let index = this.$props.startMonth; index <= this.$props.endMonth; index++) {
        listMonth.push(index);
      }
      return listMonth;
    },

    getFirstMonthWhenNotSameYear() {
      let listMonth = [];
      for (let index = this.startMonth; index <= 12; index++) {
        listMonth.push(index);
      }
      return listMonth;
    },

    getLastMonthWhenNotSameYear() {
      let listMonth = [];
      for (let index = 1; index <= this.endMonth; index++) {
        listMonth.push(index);
      }
      return listMonth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.month-selection {
  display: none;
  .month {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    &.active {
      background: transparent;
      color: #000;
      border-bottom: 2px solid $blueMid;
    }
    &.event-none {
      pointer-events: none;
    }
  }
}
@include desktop {
  .month-selection {
    display: flex;
    height: 80px;
    margin-top: 48px !important;
    .year-block {
      text-align: center;
      .year {
        text-align: center;
        font-family: $mainFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 6px;

        letter-spacing: 0.03em;

        color: $monoMid;
        border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      }
      .select-month {
        .month {
          border-radius: 0;
        }
      }
      &.second-year {
        .year {
          border-left: 1px solid rgba(42, 42, 48, 0.1);
        }
      }
    }
    .common-btn {
      width: 103px;
      &.register-btn {
        // width: 66px;
        .v-btn__content {
          font-size: 11px;
        }
      }
    }
  }
  .select-month {
    .month {
      width: 70.33px;
      height: 33px;
      flex: none;
      align-self: stretch;
      flex-grow: 1;
      margin: 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 0px 2px;
      text-align: center;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border-radius: 0;

      letter-spacing: 0.03em;

      color: $monoMid;
    }
  }
}

// dev responsive
@media (min-width: 1024px) and (max-width: 1335px) {
  .month-selection {
    .year-block {
      .select-month {
        .month {
          width: 48px;
          max-width: 50px;
        }
      }
    }
  }
}
</style>
