import {BLANK_ID} from '@/constants/registerData';
let filterTypes = ['pcaf_business_group_name', 'pcaf_business_name'];
export const handlerFilterGroupBusinessData = (sourceData, filterData, filterIndex, selectedFirst, previousCellData, isReverted) => {
  const {s, e} = sourceData;
  const column = s.columns[e.col];
  const currentItem = s.collectionView.currentItem;
  const row = currentItem?.clientRowId;
  const cellData = currentItem[column.binding];
  if (typeof filterIndex[row] === 'undefined') {
    filterIndex[row] = [];
  }
  if (previousCellData === cellData) return;
  addFilterIndex(column, filterIndex[row])
  updateFilter(column, cellData, currentItem, filterData, s, filterIndex[row], selectedFirst, row, isReverted)
  if (!cellData) {
    addFilterIndex(column, filterIndex[row], true)
  }
}

export const handlerAddGroupBusinessFilterData = (sourceData, filterData, filterIndex, selectedFirst) => {
  const {s, e} = sourceData;
  const column = s.columns[e.col];
  const currentItem = s.collectionView.currentItem;
  const row = currentItem?.clientRowId;
  if (typeof filterIndex[row] === 'undefined') {
    filterIndex[row] = [];
  }
  if (filterIndex[row].length === 0) {
    if (currentItem.id !== BLANK_ID) {
      resetFilter([column.binding], s, false, selectedFirst)
      const columnIndex = s.columns.findIndex((item) => item.binding === column.binding)
      s.columns[columnIndex].editor.itemsSource = removeDuplicateData(filterData.map(item => item[column.binding]));
    } else if (currentItem.id === BLANK_ID) {
      const allFieldsEmpty = filterTypes.every((item) => !currentItem[item])
      if (allFieldsEmpty) {
        resetFilter([filterTypes[0], filterTypes[1]], s, false, selectedFirst)
        beginningEditGetAll(s, selectedFirst, currentItem, filterData)
      }
    }
  } else {
    const selectedIndex = filterIndex[row].findIndex((item) => item === column.binding);
    if (selectedIndex >= 0) {
      switch (selectedIndex) {
        case 0:
          resetFilter([filterIndex[row][0]], s, false, selectedFirst, row)
          beginningEditLayer1(column, s, currentItem, selectedFirst, filterData)
          break;
        case 1:
          resetFilter([filterIndex[row][1]], s, false, selectedFirst)
          beginningEditLayer2(column, s, filterIndex, row, currentItem, selectedFirst, filterData)
          break;
      }
    } else {
      if (filterIndex[row].length === 1) {
        resetFilter([column.binding], s, false, selectedFirst)
        beginningEditLayer2(column, s, filterIndex, row, currentItem, selectedFirst, filterData)
      }
    }
  }
}

export const addGroupBusinessFilterByPasted = (column, filterIndex, row) => {
  if (typeof filterIndex[row] === 'undefined') {
    filterIndex[row] = [];
  }
  addFilterIndex(column, filterIndex[row])
}

const addFilterIndex = (column, filterIndex, isRemove = false) => {
  const existIndex = filterIndex?.findIndex((item) => {
    return item === column.binding;
  });
  if (isRemove) {
    filterIndex.splice(existIndex, filterIndex.length)
  }
  if (existIndex < 0) {
    filterIndex.push(column.binding)
  } else {
    filterIndex.splice(existIndex + 1, filterIndex.length)
  }
}

const updateFilter = (column, cellData, currentItem, filterData, source, filterIndex, selectedFirst, row, isReverted) => {
  const allFieldsEmpty = filterTypes.every((item) => !currentItem[item])
  if (allFieldsEmpty) {
    updateGetAllData(filterData, source, selectedFirst, column)
    selectedFirst = null
    return
  }
  filterCondition(column, cellData, currentItem, filterData, source, filterIndex, selectedFirst, row, isReverted)
  if (!selectedFirst) {
    selectedFirst = column.binding
  }
}

const updateGetAllData = (filterData, source, selectedFirst, column) => {
  resetFilterIndex([filterTypes[0], filterTypes[1]], source)
  resetFilter([filterTypes[0], filterTypes[1]], source, false, selectedFirst)

  let filterList = {};
  filterData.forEach((item) => {
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const resetFilterIndex = (items, source) => {
  items.forEach((item) => {
    source[item] = null
  })
}

const resetFilter = (items, source, isRemove = false, selectedFirst) => {
  if (isRemove) {
    items = filterTypes.filter((item) => !items.includes(item)); // get elements not in filterTypes
  }
  items.forEach((type) => {
    const columnIndex = source.columns.findIndex((column) => column.binding === type)
    if (columnIndex >= 0 && selectedFirst !== type) {
      source.columns[columnIndex].editor.itemsSource = []
    }
  })
}

const addItemToList = (column, item, selectedFirst, filterList) => {
  if (typeof filterList[column.binding] === 'undefined') {
    filterList[column.binding] = []
  }
  switch (column.binding) {
    case filterTypes[0]:
      addGroupCompanyBeginning(item, selectedFirst, filterList[column.binding]);
      break;
    case filterTypes[1]:
      addCompanyBeginning(item, selectedFirst, filterList[column.binding]);
      break;
  }
}

const addItemsSource = (filterList, source, column) => {
  for (const key in filterList) {
    if (filterList[key].length > 0) {
      const columnIndex = source.columns.findIndex((item) => item.binding === column.binding)
      source.columns[columnIndex].editor.itemsSource = removeDuplicateData(filterList[key])
    }
  }
}

const filterCondition = (column, cellData, currentItem, filterData, source, filterIndex, selectedFirst, row, isReverted) => {
  const selectedIndex = filterIndex.findIndex((item) => item === column.binding);
  if (selectedIndex < 0) return;
  let bindingColumns = []
  switch (selectedIndex) {
    case 0:
      bindingColumns = [column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, currentItem)
      filterLayer1(column, cellData, source, selectedFirst, filterData, currentItem)
      break;
    case 1:
      bindingColumns = [filterIndex[0], column.binding]
      resetFilter(bindingColumns, source, false, selectedFirst)
      resetSelectedData(bindingColumns, source, currentItem)
      filterLayer2(column, cellData, source, selectedFirst, filterData, filterIndex, currentItem)
      break;
  }
}

const resetSelectedData = (items, source, currentItem) => {
  let types = [];
  types = filterTypes.filter((item) => !items.includes(item)); // get elements not in filterTypes
  types.forEach((type) => {
    const columnIndex = source.columns.findIndex((column) => column.binding === type)
    if (columnIndex >= 0) {
      source.setCellData(source.rows.findIndex(row => row.dataItem?.clientRowId === currentItem?.clientRowId) || 0, columnIndex, null, false, true)
    }
  });
}

const filterLayer1 = (column, cellData, source, selectedFirst, filterData, currentItem) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const filterLayer2 = (column, cellData, source, selectedFirst, filterData, filterIndex, currentItem) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[0], currentItem[filterIndex[0]])) return;
    if (!checkItemExist(item, column.binding, cellData)) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const checkItemExist = (item, filterType, filterItem) => {
  if (!filterDataByBinding(item, filterType, filterItem)) return false
  return true
}

const filterDataByBinding = (item, binding, cellData) => {
  if ((cellData !== null && item[binding] === cellData) || cellData === null) {
    return true
  }
  return false
}

const disabledFacilityCondition = (currentItem, item) => {
  if ((currentItem?.id === BLANK_ID || currentItem?.id) && item.status === false) return true;
  return false;
}

const addGroupCompanyBeginning = (item, selectedFirst, list) => {
  if (selectedFirst === filterTypes[0]) return;
  // check company exist
  const existIndex = list?.findIndex((organiItem) => {
    return organiItem.key === item[filterTypes[0]];
  });
  if (existIndex < 0 && item[filterTypes[0]]) {
    list.push(item[filterTypes[0]])
  }
}

const addCompanyBeginning = (item, selectedFirst, list) => {
  if (selectedFirst === filterTypes[1]) return;
  // check company exist
  const existIndex = list?.findIndex((organiItem) => {
    return organiItem.key === item[filterTypes[1]];
  });
  if (existIndex < 0 && item[filterTypes[1]]) {
    list.push(item[filterTypes[1]])
  }
}

const beginningEditGetAll = (source, selectedFirst, currentItem, branchData) => {
  let filterList = {};
  filterTypes.forEach((type) => {
    filterList[type] = [];
  })
  branchData.forEach((item) => {
    if (disabledFacilityCondition(currentItem, item)) return;
    addGroupCompanyBeginning(item, selectedFirst, filterList[filterTypes[0]])
    addCompanyBeginning(item, selectedFirst, filterList[filterTypes[1]])
  })
  filterTypes.forEach((type) => {
    const columnIndex = source.columns.findIndex((item) => item.binding === type)
    if (columnIndex >= 0) {
      source.columns[columnIndex].editor.itemsSource = removeDuplicateData(filterList[type])
    }
  })
}

const removeDuplicateData = (arr) => {
  return arr = [...new Set(arr)] 
}

const beginningEditLayer1 = (column, source, currentItem, selectedFirst, filterData) => {
  let filterList = [];
  filterData.forEach((item) => {
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

const beginningEditLayer2 = (column, source, filterIndex, row, currentItem, selectedFirst, filterData) => {
  let filterList = {};
  filterData.forEach((item) => {
    if (!checkItemExist(item, filterIndex[row][0], currentItem[filterIndex[row][0]])) return;
    if (disabledFacilityCondition(currentItem, item)) return;
    addItemToList(column, item, selectedFirst, filterList);
  })
  addItemsSource(filterList, source, column)
}

//
export const handleGetBusinessGroupId = (companyName, groupName, objGroup) => {
  if(!companyName || !groupName) return null
  return objGroup.company_obj[companyName][groupName]?.id
}