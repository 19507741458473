<template>
  <div class="term-of-use-container">
    <div v-html="data" v-show="data" class="content"></div>
  </div>
</template>
<script>
import { termsOfUse } from '@/api/others';

export default {
  data() {
    return {
      data: '',
    };
  },
  mounted() {
    this.getTermsOfUse();
  },
  methods: {
    getTermsOfUse() {
      termsOfUse().then((response) => {
        this.data = response;
      });
    },
  },
};
</script>
