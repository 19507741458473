<template>
  <div class="container-fluid padding-top-layout">
    <div class="wrap">
      <page-title class="padding-left-layout" title="一時保存一覧" />
    </div>
    <div
      class="category-table main-table custom-table view-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="temporaryGrid"
        :grid-columns="temporaryColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :showFocus="false"
        :isResizeCustome="false"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
      />
    </div>
    <log-confirm-popup :dialog="dialog" :contents="logContents" @close="dialog = false" />
  </div>
</template>

<script>
import DataTable from "@/components/category/data-table";
import PageTitle from "@/components/pageTitle/pageTitle.vue";
import LogConfirmPopup from "@/components/dialogs/log-confirm-popup";

import { getListWorkflowApi } from "@/api/registerData";
import { getStatusNameById } from "@/utils/status";
import { getDataLayer, getScopeName, getCategoryName } from "@/utils/registerData";
import { formatDateTimeDataTable } from "@/utils/datetimeFormat";

import { ROUTES } from "@/router/constants";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID } from "@/constants/registerData";
import { mapActions, mapState } from "vuex";
import { CollectionView } from "@mescius/wijmo";
import { UndoStack } from "@/concerns/utils/undo-stack";
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import * as wjcCore from "@mescius/wijmo";
import throttle from "lodash/throttle";
import { $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
export default {
  name      : "listTemporary",
  components: {
    DataTable,
    PageTitle,
    LogConfirmPopup
  },
  data() {
    return {
      listTemporary  : [],
      breadCrumb     : [
        {
          text    : "ダッシュボード",
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : "排出量登録",
          disabled: false,
          href    : ROUTES.EMISSIONS
        },
        {
          text    : "一時保存一覧",
          disabled: true,
          href    : ROUTES.LIST_EMISSION_TEMPORARY
        }
      ],
      temporaryColumn: [],
      temporaryGrid  : null,
      flexGrid       : null,
      temporaryData  : [],
      dialog         : false,
      logContents    : []
    };
  },
  mounted() {
    this.updateBreadCrumb(this.breadCrumb);
    this.handleGetDataTemporary();
  },
  methods : {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    initGrid(grid) {
      document.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange        = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection         = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              const cellRange        = new wjGrid.CellRange(0, currentSelection.col);
              grid.selection         = cellRange;
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
              grid.selection         = cellRange;
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, 1);
              grid.selection         = cellRange;
            }
          }

          if (event.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));

                grid.itemsSource.commitNew();
                grid.itemsSource.clearChanges();
              });
            }
          }
        },
        false
      );

      // add tooltip
      this.flexGrid = grid;
      this.flexGrid.onSelectionChanged(null);
      grid.onSelectionChanged(null);
    },
    onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      this.$router.push({ path: `/emissions/register/list-temporary/${rowData.item.id}` });
    },
    onLogClicking(row) {
      this.dialog = true;
      // TODO: set content log by row id
    },
    async handleGetDataTemporary() {
      const payload           = {
        action: "listDraft"
      };
      const temporaryResponse = await getListWorkflowApi(payload);
      if (temporaryResponse.data.length > 0) {
        this.temporaryData = temporaryResponse.data.map(temporaryItem => {
          return {
            ...temporaryItem,
            status         : getStatusNameById(temporaryItem.status),
            contractor_name: temporaryItem.contractor_name,
            submitter      : temporaryItem?.submitter_name,
            log_confirm    : "ログ確認",
            scope          : getScopeName(parseInt(temporaryItem.scope)),
            category       : getCategoryName(parseInt(temporaryItem.scope), parseInt(temporaryItem.category)),
            layer1         : getDataLayer(temporaryItem.scope, temporaryItem.category, temporaryItem.pattern_id)?.method,
            layer2         : getDataLayer(temporaryItem.scope, temporaryItem.category, temporaryItem.pattern_id)?.sub_method,
            lasted_update  : formatDateTimeDataTable(temporaryItem.updated_at)
          };
        });
      }
      this.logContents = [
        {
          name   : "Mr 93",
          date   : "2022/01/10 02:05:11",
          content: "一時保存中。"
        },
        {
          name   : "Ms 9333",
          date   : "2022/02/10 04:07:41",
          content: "一時保存中。"
        },
        {
          name   : "Mr 93",
          date   : "2022/01/10 02:05:11",
          content: "一時保存中。"
        }
      ];
      this.defineTable();
    },
    handleEvent() {
      let isHandlingCollectionChange = false;
      this.temporaryGrid.collectionChanged.addHandler(throttle(async() => {
        if (isHandlingCollectionChange) {
          return;
        }

        isHandlingCollectionChange = true;
        setTimeout(() => {
          isHandlingCollectionChange = false;
        }, 100);

        let edited = {};
        this.temporaryGrid.itemsEdited.forEach((temporaryItem, index) => {
          if (temporaryItem.title) {
            // prepare data: title, id, ...
            const prepareTemporaryData = {
              id   : temporaryItem.id,
              title: temporaryItem.title
            };

            edited[index] = prepareTemporaryData;
          }
        });

        if (Object.values(edited).length) {
          try {
            this.handleUpdateTemporarydata({ data: Object.values(edited) });
            this.temporaryGrid.itemsEdited.length = 0;
          } catch (err) {
            this.temporaryGrid.itemsEdited.length = 0;
          }
        }
      }, 300));
    },
    handleUpdateTemporarydata(temData) {
      console.log(temData);
      // TODO: call api with params
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;

      if (!flexgrid) {
        return;
      }
      this.canUndo = false;
      this.canRedo = false;

      const stateChanged = (event) => {
        this.canUndo = event.canUndo;
        this.canRedo = event.canRedo;
      };

      this.undoStack = new UndoStack(flexgrid, stateChanged);
    },
    initialView() {
      if (this.temporaryGrid) {
        this.temporaryData = this.temporaryGrid.items.filter((temporaryItem) => temporaryItem.id && temporaryItem.id !== BLANK_ID);
      }

      this.temporaryGrid = new CollectionView([...this.temporaryData], {
        trackChanges: true,
      });
      // this.addBlankItemsToView(10);
      this.handleEvent();
      this.flexGrid.columnGroups = this.getTemporaryColumns();
    },
    addBlankItemsToView(count) {
      const lastClientId = this.temporaryGrid.itemCount;
      for (let index = 1; index <= count; index++) {
        this.temporaryGrid.addNew(this.blankData(lastClientId + index));
      }

      this.temporaryGrid.commitNew();
      this.temporaryGrid.clearChanges();
    },
    getTemporaryColumns() {
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : "データID",
          binding     : "data_id",
          minWidth    : 150,
          maxWidth    : 150,
          allowSorting: true,
          isRequired  : false,
          wordWrap    : true,
          cssClass    : "btn-db",
          cellTemplate: CellMaker.makeButton({
            text : "<button class=\"temporary-btn_custom\">${text}</button>",
            click: (event, context) => this.onIdClicking(context)
          }),
          cssClassAll : "height34 temporary-cell--btn"
        },
        {
          header      : "ステータス",
          binding     : "status",
          minWidth    : 150,
          maxWidth    : 150,
          allowSorting: true,
          isRequired  : false,
          cssClassAll : "height34"
        },
        {
          header      : "タイトル",
          binding     : "title",
          minWidth    : 250,
          maxWidth    : 980,
          allowSorting: true,
          isReadOnly  : false,
          cssClassAll : "height34"
        },
        {
          header      : "法人名",
          binding     : "contractor_name",
          minWidth    : 220,
          maxWidth    : 220,
          allowSorting: true,
          isRequired  : false,
          cssClassAll : "height34"
        },
        {
          header      : "スコープ",
          binding     : "scope",
          minWidth    : 120,
          maxWidth    : 150,
          allowSorting: true,
          cssClassAll : "height34"
        },
        {
          header      : "カテゴリ",
          binding     : "category",
          minWidth    : 120,
          maxWidth    : 150,
          allowSorting: true,
          cssClassAll : "height34"
        },
        {
          header : "算定方法",
          align  : "center",
          columns: [
            {
              header      : "1段目",
              binding     : "layer1",
              minWidth    : 150,
              maxWidth    : 400,
              allowSorting: true,
              cssClassAll : "height34"
            },
            {
              header      : "2段目",
              binding     : "layer2",
              minWidth    : 150,
              maxWidth    : 400,
              allowSorting: true,
              cssClassAll : "height34"
            }
          ]
        },
        {
          header      : "最終更新日時",
          binding     : "lasted_update",
          minWidth    : 200,
          width: '*',
          allowSorting: true,
          cssClassAll : "height34"
        },
        {
          header      : "ログ確認",
          binding     : "log_confirm",
          minWidth    : 120,
          maxWidth    : 200,
          allowSorting: true,
          cssClass    : "btn-db",
          cssClassAll : "height34 temporary-cell--btn",
          cellTemplate: CellMaker.makeButton({
            text : "<button class=\"temporary-btn_custom\">${text}</button>",
            click: (event, context) => this.onLogClicking(context)
          })
        }
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }
      let rc                       = this.flexGrid.cells?.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    defineTable() {
      this.temporaryColumn = this.getTemporaryColumns();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
    }
  },
  computed: {
    ...mapState("commonApp", ["isExpand"]),
    ...mapState("registerData", ["isFullScreen"])
  }
};
</script>

<style lang="scss" scoped>
@import './styles/index.scss';
</style>

