<template>
  <div class="user-encoding" :class="{'mobile': isMobile,'export-popup': isExportPopup}">
    {{isCheckShowText}}
  </div>
</template>
<script>
import { getUserInfo } from "@/api/auth";
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    isExportPopup: {
      type: Boolean,
      default: false,
    },
    isImportData: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      userEncoding: "Shift JIS",
    }
  },
  async mounted() {
    await this.getUserEncoding();
  },
  computed: {
    isCheckShowText () {
      return this.isImportData ?  this.$t("a_export_report.description_csv_format_import", {userEncoding: this.userEncoding}) : this.$t("a_export_report.description_csv_format", {userEncoding: this.userEncoding})
    }
  },
  methods: {
    async getUserEncoding() {
      try {
        const response = await getUserInfo();
        this.userEncoding = response.encoding === 'UTF-8' ? response.encoding : "Shift JIS";
        this.$emit('getUserEncoding', this.userEncoding);
      } catch (err) {
        console.log(err);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.user-encoding {
  color: $monoBlack;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.75px;
  padding-top: 16px;
  white-space: pre-wrap;
}
@include desktop {
  .user-encoding {
    padding-top: 0;
    &.mobile {
      display: none;
    }
    &.export-popup {
      padding-top: 16px;
    }
  }
}
</style>