<template>
  <div>
    <RegisterData />
  </div>
</template>

<script>
import RegisterData from '@/views/emissions/NewRegister/RegisterData.vue';
export default {
  components: {
    RegisterData,
  },
};
</script>

<style lang="scss" scoped></style>
