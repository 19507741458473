<template>
  <div class="w-full">
    <div class="title-read">
      <div class="title-1">{{ $t('ocr_service.label_reading_result') }}</div>
      <div class="sub-title">{{ $t('ocr_service.label_correct_possible') }}</div>
      <div v-show="!listAlldataFile" class="input-field-read-file">
        <export-pulldown
          :disabled="true"
          :placeholder="$t('ocr_service.placeholder_no_data')"
          :arrowDownBtn="false"
          :showFullText="true"
          type="listScopeCategories"
        />
      </div>
    </div>
    <div class="content-table" v-show="listAlldataFile">
      <div class="data-read-file">
        <data-table
          :init-grid="initGrid"
          :data-source="dataReadFileGrid"
          :grid-columns="externalColumns"
          :allowAddNew="false"
          :rowFocus="1"
          :isShowToolbar="false"
          :showFocus="true"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import { CollectionView } from '@mescius/wijmo';
import DataTable from '@/components/category/data-table';
import ErrorPopup from '@/components/dialogs/notification-popup.vue';
import { getWidthByTextContent } from '@/utils/calcTextWidth';
import { validateNumberField } from '@/concerns/newRegisterData/wijmo.helper';
import { formatValue, $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandComma } from '@/utils/convertNumber';
import ExportPulldown from '@/components/pulldown/exportReport';

export default {
  components: { InputField, CommonButton, DataTable, ErrorPopup, ExportPulldown },
  props: {
    listAlldataFile: {
      type: Array || null,
      default: null,
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    },

    typeForm: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      dataReadFile: null,
      gridColumns: [],
      externalColumns: [],
      flexgrid: null,
      dataReadFileGrid: null,
      selectedFilterColumn: null,
    };
  },

  watch: {
    listAlldataFile: {
      handler(value) {
        if (value) {
          if (this.flexgrid) {
            this.flexgrid.columnGroups = this.initGridColumn();
          }
          this.dataReadFileGrid = new CollectionView(value, {
            trackChanges: true,
          });
          
          this.dataReadFileGrid.getError = this.getError;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initGridColumn() {
      return [
        {
          header: this.$t('56_pattern.table_memo_1'),
          binding: 'memo_1',
          minWidth: 6,
          maxWidth: 198,
          allowSorting: false,
          isRequired: false,
          isReadOnly: this.isReadOnly,
          wordWrap: true,
        },
        {
          header: this.$t('56_pattern.table_wsu_value'),
          binding: 'value',
          minWidth: getWidthByTextContent(this.$t('56_pattern.table_wsu_value')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: this.isReadOnly,
          wordWrap: true,
          align: 'right',
          cssClassAll: 'single-row',
        },
        {
          header: this.$t('56_pattern.table_wsu_unit'),
          binding: 'unit',
          minWidth: getWidthByTextContent(this.$t('56_pattern.table_wsu_unit')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          align: 'right',
          cssClassAll: 'single-row',
        },
        {
          header: this.$t('56_pattern.table_memo_2'),
          binding: 'memo_2',
          minWidth: 70,
          maxWidth: 1428,
          allowSorting: false,
          isRequired: false,
          isReadOnly: this.isReadOnly,
          wordWrap: true,
        },
      ];
    },

    initGrid(grid) {
      this.flexgrid = grid;
      if (this.flexgrid) {
        this.flexgrid.columnGroups = this.initGridColumn();
      }

      this.flexgrid.cellEditEnded.addHandler((s, e) => {
        const column = s.columns[e.col];
        if (column.binding === 'value') {
          const { row, col } = e.range;
          let cellData = s.getCellData(row, col, false)?.toString();
          const isNumber = $_helper_isNumberType(cellData);
          const range = 25;
          const subStringRang = cellData?.includes('-') ? range + 1 : range;
          const valueFormat = addThoundSandComma(formatValue(cellData)?.substring(0, subStringRang));
          s.setCellData(row, col, isNumber ? valueFormat : '', false, true);
        }
      });
    },

    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },

    getError(item, propName) {
      const listItemvalidate = ['memo_1', 'memo_2', 'value'];
      const numberField = ['value'];

      if (listItemvalidate.includes(propName)) {
        if (
          item[propName] === null ||
          item[propName] === '' ||
          item[propName] === undefined ||
          (!item[propName] && item[propName] !== 0)
        ) {
          return this.$t('validation.error_required_field');
        }
      }

      if (numberField.includes(propName)) {
        if (!item[propName] && item[propName] !== 0) {
          return this.$t('validation.error_required_field');
        } else {
          return validateNumberField(String(item[propName]));
        }
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>

.content-table {
  background-color: $monoOffWhite;
  width: 100%;
  .data-read-file {
    width: 80%;
    max-width: 496px;
    padding: 7px 16px 9px 16px;
    margin: 16px auto 0 auto;
  }
}
.title-read {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: $monoBlack;
  font-size: 14px;
  font-style: normal;
  .sub-title {
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.7px;
  }
  .title-1 {
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.42px;
  }
  .input-field-read-file {
    width: 100%;
  }
}
</style>
<style lang="scss">
.input-field-read-file {
  .pulldown-wrapper {
    &:not(.has-arrow) {
      &:hover {
        #dropdownInputText {
          background: $goldLight;
          cursor: pointer;
          .input-text {
            color: $monoWhite;
          }
          .placeholder {
            &.center-text {
              color: $monoMid;
            }
          }
          &.selection-show {
            background-color: $monoOffWhite;
            .input-text {
              color: $monoBlack;
            }
          }
        }
      }
    }
  }
}
</style>